import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store/index.js';
import axios from 'axios';

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
const app = createApp(App);
app.use(router);
app.use(store);

// Global mixin to check if token is valid
/* app.mixin({
    async created() {
        try {
            await this.$store.dispatch('checkToken');
        } catch (err) {
            console.log(err);
        }
    }
}); */


app.mount('#app');