<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Dev Loans
                </template>
            </page-header>
        </template>
        
        <template v-if="isLoading">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
            <clip-loader  :color="loaderColor" ></clip-loader>
            </div>
        </template>
        <template v-else>
            <dev-loan-meta></dev-loan-meta>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="table-container">
                <div class="t-header">Dev Loans Summary</div>
                    <div id="basicExample_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="basicExample_length">
                                    <label>Display 
                                        <select name="basicExample_length" aria-controls="basicExample" class="form-control form-control-sm selectpicker" 
                                        v-model="pageSize"
                                        @change="onPageSizeChange"
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>>
                                        </select> 
                                        Records Per Page
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="basicExample_filter" class="dataTables_filter">
                                    <label>Search:<input type="search" class="form-control form-control-sm selectpicker" placeholder="" aria-controls="basicExample"></label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table id="basicExample" class="table custom-table dataTable no-footer" role="grid" aria-describedby="basicExample_info">
                                        <thead>
                                            <tr role="row">
                                                <th aria-controls rowspan="1" colspan="1" >Loan Code</th>
                                                <th aria-controls rowspan="1" colspan="1" >Guarantor</th>
                                                <th aria-controls rowspan="1" colspan="1" >Date Applied</th>
                                                <th aria-controls rowspan="1" colspan="1" >Amount</th>
                                                <th aria-controls rowspan="1" colspan="1" >Amount To Repay</th>
                                                <th aria-controls rowspan="1" colspan="1" >Duration</th>
                                                <th aria-controls rowspan="1" colspan="1" >Loan Status</th>
                                                <th aria-controls rowspan="1" colspan="1" >Monthly Installment</th>
                                                <th aria-controls rowspan="1" colspan="1" >Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="loan in data.data" :key="loan.id" role="row">
                                                <td>{{ loan.loan_code }}</td>
                                                <td v-if="loan.guarantors !== null">{{ loan.guarantor.guarantor_name  }} {{ loan.guarantor.phone_number }}</td>
                                                <td v-else> N/A </td>
                                                <td>{{ loan.created_at }}</td>
                                                <td>{{ loan.amount }}</td>
                                                <td>{{ loan.amount_to_repay }}</td>
                                                <td>{{ loan.duration }}</td>
                                                <td>{{ loan.status_name }}</td>
                                                <td>{{ loan.monthly_repayment }}</td>
                                                <td>
                                                    <div class="my-custom_table-spacing">
                                                        <template v-if="loan.status != 1 && loan.status != 2 && loan.status != 6 && loan.status != 8">
                                                        <router-link :to="{ name: 'project_deposit', params: { project_code: loan.loan_code }}">
                                                            <input class="btn btn-secondary btn-sm" type="button" value="Pay">
                                                        </router-link>
                                                        </template>
                                                        <router-link :to="{ name: 'project_details', params: { project_code: loan.loan_code }}">
                                                            <input class="btn btn-primary btn-sm" type="button" value="View">
                                                        </router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    
                   <pagination
                            :total-pages="data.last_page"
                            :per-page="10"
                            :current-page="data.current_page"
                            @pagechanged="onPageChange"
                            :maxVisibleButtons="(data.last_page <= 5) ? data.last_page : 5"
                            :to="data.to"
                            :from="data.from"
                            :total="data.total"
                        /> 
                </div>
            </div>
        </div>
        </template>
        
    </base-component>
</template>

<script>
import BaseComponent from '../ui/BaseComponent.vue';
import PageHeader from '../layout/ThePageHeader.vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import DevLoanMeta from '../members/DevLoanMeta.vue';
import pagination from './Paginator.vue';


export default {
    components: {
        BaseComponent,
        PageHeader,
        ClipLoader,
        DevLoanMeta,
        pagination
    },
    props: ['id'],
    data() {
        return {
            data: '',
            userId: this.$store.getters.userId,
            error: '',
            isLoading: true,
            loaderColor: 'blue',
            currentPage: 1,
            pageSize: 10,
        }
    },
     mounted() {
        const actionPayload = {
                userId: this.userId,
           };
        let devloans = this.$store.dispatch('memberDevLoans', actionPayload);
        devloans.then(res => this.data = res.data.data)
        .then(() => {
            this.isLoading = false;
        })
        .catch(err => console.log(err));
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page;
            const actionPayload = {
                userId: this.userId,
                page: page,
                pageSize: this.pageSize
           };
            let devloans = this.$store.dispatch('memberDevLoans', actionPayload);
            devloans.then(res => this.data = res.data.data)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => console.log(err));
        },
        onPageSizeChange() {
            const actionPayload = {
                userId: this.userId,
                pageSize: this.pageSize
           };
            let devloans = this.$store.dispatch('memberDevLoans', actionPayload);
            devloans.then(res => this.data = res.data.data)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => console.log(err));
        },
    },
}
</script>

<style scoped>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.table-container {
    padding: .6rem;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 1rem; 
}
.table-container h5.table-title {
    font-size: .9rem;
    padding: 10px 15px;
    margin: 0; 
}
.table-container .t-header {
    margin: -10px -.6rem 0 -.6rem;
    padding: 12px 10px;
    font-weight: 700; 
}
.table {
    background: #ffffff;
    color: #2e323c;
    font-size: .85rem; 
    width: 100%;
    border-collapse: collapse;
}
.table thead th {
    padding: .8rem 1rem;
    font-weight: 600;
    border-bottom: 2px solid #e0e3ec; 
    vertical-align: bottom;
}
.table tr {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; 
}
.table td {
    border-top: 1px solid #e0e3ec;
    vertical-align: middle;
    padding: .65rem 1rem; 
}
.table th {
    border-top: 1px solid #e0e3ec; 
}
.table .td-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row; 
}
.table .td-actions a.icon {
    margin: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #ffffff; 
}
.table .td-actions a.icon i {
    font-size: .7rem; 
}
.table .td-actions a.icon.red {
    background: #ff1e1e;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ff1e1e 0%, #ff3e3e 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff1e1e), color-stop(100%, #ff3e3e));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ff1e1e 0%, #ff3e3e 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ff1e1e 0%, #ff3e3e 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ff1e1e 0%, #ff3e3e 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ff1e1e 0%, #ff3e3e 100%);
    /* W3C */ 
}
.table .td-actions a.icon.green {
    background: #00ab3c;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00ab3c 0%, #00bb42 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00ab3c), color-stop(100%, #00bb42));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #00ab3c 0%, #00bb42 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #00ab3c 0%, #00bb42 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #00ab3c 0%, #00bb42 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #00ab3c 0%, #00bb42 100%);
    /* W3C */ 
}
.table .td-actions a.icon.blue {
    background: #0074d6;
    /* Old browsers */
    background: -moz-linear-gradient(top, #0074d6 0%, #007ae1 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0074d6), color-stop(100%, #007ae1));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #0074d6 0%, #007ae1 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #0074d6 0%, #007ae1 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #0074d6 0%, #007ae1 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #0074d6 0%, #007ae1 100%);
    /* W3C */ 
}
.table a.link {
    color: #007ae1;
    font-weight: 600;
    text-decoration: underline; 
}
.table .flag-img {
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0; 
}
.table-hover tbody tr:hover {
    background: #f5f6fa;
    color: #bcd0f7; 
}

.table-striped tbody tr:nth-of-type(odd) {
    background: #161f33; 
}
.table-bordered {
    border: 1px solid #e0e3ec; 
}
.table-bordered td, .table-bordered th {
    border: 1px solid #e0e3ec; 
}
.table-sm th {
    padding: .5rem .7rem; 
}
.table-sm td {
     padding: .3rem .7rem; 
}
.table-dark {
    background: #5fa22d;
    color: #ffffff; 
}
.table-dark thead th {
    border-color: #7ac146; 
}
.table-dark th {
    border-color: #7ac146; 
}
.table-dark td {
    border-color: #7ac146; 
}
.table-primary {
    background: #007ae1;
    color: #ffffff; 
}
.table-primary thead th {
    border-color: #006cc8; 
}
.table-primary th {
    border-color: #006cc8; 
}
.table-primary td {
    border-color: #006cc8; 
}
table.dataTable {
    clear: both;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    max-width: none !important;
}
@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}
@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}
@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}
@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
    border: 0;
}
.custom-table {
    border: 1px solid #e0e3ec; 
}
.custom-table thead {
    background: #007ae1; 
}
.custom-table thead th {
    border: 0;
    color: #ffffff; 
}
.custom-table > tbody tr:hover {
    background: #fafafa; 
}
.custom-table > tbody tr:nth-of-type(even) {
    background-color: #ffffff; 
}
.custom-table > tbody td {
    border: 1px solid #e6e9f0; 
}
.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}
div.dataTables_wrapper div.dataTables_info {
    color: #5a8dee;
    font-size: .775rem;
    margin: 10px 0 0 0;
    padding: 0 0 15px 0;
    white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
    white-space: nowrap;
    text-align: right;
    margin: 10px 0 0 0;
    padding: 0 0 10px 0px;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
}
.dataTables_paginate .pagination .page-item.disabled .page-link {
    background: transparent;
}
.pagination .page-item.disabled .page-link {
    opacity: 0.4;
    background: #007ae1;
    color: rgba(255, 255, 255, 0.4);
}
.dataTables_paginate .pagination .page-item .page-link:hover {
    background: #42a8ff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px; }
.dataTables_paginate .pagination .page-item .page-link {
    font-size: .75rem;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}
.pagination .page-item .page-link {
    background: transparent;
    color: #2e323c;
    margin: 0;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    min-width: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination .page-item .page-link:hover {
    background: #f5f6fa;
    color: #2e323c; }
.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
}
.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.dataTables_paginate .pagination .page-item.active .page-link {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}
.pagination .page-item.active .page-link {
    background: #007ae1;
    color: #ffffff;
    pointer-events: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 225, 0.15);
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}
table.dataTable td, table.dataTable th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
    width: 75px;
    display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    font-weight: 400;
    background: #ffffff;
    color: #2e323c;
}
.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}
.my-custom_table-spacing {
    display: flex;
    flex-wrap: wrap;
}
.my-custom_table-spacing .btn {
    margin: .3rem;
    display: flex;
    align-items: center;
}
</style>