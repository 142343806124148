export default {
    userId(state) {
        return state.userId;
    },
    token(state) {
        return state.token;
    },
    phoneNumber(state) {
        return state.phoneNumber;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    userType(state) {
        return state.userType;
    },
    toggleSidebar(state) {
        return state.toggleSidebar
    }
};