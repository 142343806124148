<template>
    <!-- Container fluid start -->
    <div class="container-fluid">
        <!-- Row start -->
        <div class="row gutters">
            <div class="col-12">
                <!-- Footer start -->
                <div class="footer">
                    Copyright UniTrust {{ new Date().getFullYear() }}
                </div>
                <!-- Footer end -->
            </div>
        </div>
        <!-- Row end -->
    </div>
    <!-- Container fluid end -->
</template>

<style scoped>
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.footer {
    font-size: .65rem;
    padding: .8rem 1rem;
    margin: 0 8px 20px 8px;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; 
}
</style>