<template>
    <!-- Row start -->
    <div class="row gutters">
        <slot></slot>
    </div>
    <!-- Row end -->

</template>

<style>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.gutters > .col, .gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}

</style>