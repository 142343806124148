<template>
    <!-- Main container start -->
	<div class="main-container">
        <slot></slot>
	</div>
</template>

<style scoped>
.main-container {
    margin: 0;
    padding: 1.5rem 1.5rem 0 1.5rem;
    min-height: calc(100vh - 127px); 
}
</style>