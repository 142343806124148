<template>
    <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="card">
            <div class="payments-card">
                <h6>Amount availble for borrowing(limited to 10,000 as total of uniflex loans)</h6>
                <h4>KES {{ availableFunds }}</h4>
                <div class="custom-btn-group mt-2" v-if="availableFunds > 0">
                    <button class="btn btn-danger" @click="getUniflexForm"><i class="icon-credit-card"></i>Apply For Loan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userId: this.$store.getters.userId,
            availableFunds: '',
            error: ''
        }
    },
    mounted() {
        const actionPayload = {
                userId: this.userId,
           };
        let availableAmount = this.$store.dispatch('getUniflexMax', actionPayload);
        availableAmount.then(res => this.availableFunds = res.data).catch(err => console.log(err));
    },
    methods: {
        getUniflexForm() {
           return  this.$router.push('/uniflex-loan-application');
        },
    }
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}
.payments-card {
    padding: 1rem;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 125px;
}
.custom-btn-group {
    display: flex;
    flex-wrap: wrap;
}
.mt-2, .my-2 {
    margin-top: .5rem!important;
}
.custom-btn-group .btn {
    margin: .3rem;
    display: flex;
    align-items: center;
}
.btn-danger {
    color: #ffffff;
    background: #ff3e3e;
}
.btn i {
    vertical-align: middle;
    margin: 0 5px 0 0;
}
.payments-card h6 {
    margin: 0 0 4px 0;
    font-weight: 400;
    font-size: .99rem;
}
.payments-card h4 {
    margin: 0;
    font-weight: 700;
    line-height: 100%;
}
</style>