<template>
	<router-view name="register"></router-view>
	<router-view name="login"></router-view>
	<router-view name="dashboard"></router-view>
	<router-view name="devloanform"></router-view>
	<router-view name="uniflexform"></router-view>
	<router-view name="accountsettings"></router-view>
	<router-view name="wallet"></router-view>
	<router-view name="devloans"></router-view>
	<router-view name="uniflexloans"></router-view>
	<router-view name="savings"></router-view>
	<router-view name="personalprojects"></router-view>
	<router-view name="unitrustprojects"></router-view>
	<router-view name="unitrustuniflex"></router-view>
	<router-view name="unitrust_dev_loans"></router-view>
	<router-view name="disbursements"></router-view>
	<router-view name="phone_verification"></router-view>
	<router-view name="notfound"></router-view>
	<router-view name="shambaProject"></router-view>
	<router-view name="insuranceProject"></router-view>
	<router-view name="walletTopup"></router-view>
	<router-view name="walletConfirmation"></router-view>
	<router-view name="projectDeposit"></router-view>
	<router-view name="projectDetails"></router-view>
	<router-view name="targetedSavingsForm"></router-view>
	<router-view name="targetedSavings"></router-view>
	<router-view name="insuranceForm"></router-view>
	<router-view name="devloansGuaranteed"></router-view>
	<router-view name="guarantorRequests"></router-view>
	<router-view name="devloansForReview"></router-view>
	<router-view name="forgotPassword"></router-view>
	<router-view name="memberShares"></router-view>
	<router-view name="sharesPurchase"></router-view>
	<router-view name="resetPassword"></router-view>
	<router-view name="unitrust_project_details"></router-view>
	<router-view name="unitrust_members"></router-view>
	<router-view name="unitrust_stk_payments"></router-view>
	<router-view name="unitrust_c2b_payments"></router-view>
	<router-view name="unitrust_loan_savings"></router-view>
	<router-view name="unitrust_shamba"></router-view>
	<router-view name="unitrust_target_savings"></router-view>
	<router-view name="unitrust_shares"></router-view>
	<router-view name="unitrust_insurance"></router-view>
	<router-view name="unitrust_uploaded_payments"></router-view>
</template>

<script>
import axios from 'axios';
import store from './store/index.js';
import router from './router.js';

import '@/assets/fonts/style.css';

export default {
	created() {
        axios.interceptors.response.use(function (response) {
            if (response.data.status_code === 401) {
                store.dispatch('logout');
                router.push('/login');
            } else {
                return response;
            }
        }, function (err) {
            if (err.status === 401) {
                store.dispatch('logout')
            }
            throw err;
        });
    }
} 
</script>

<style>
html {
    font-size: 100%;
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font: 400 .875rem 'Open Sans', sans-serif;
    color: #2e323c;
    background: #f5f6fa;
    position: relative;
    height: 100%;
    text-align: left;
}
*, ::after, ::before {
    box-sizing: border-box;
}
a {
    cursor: pointer;
	text-decoration: none;
    background-color: transparent;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }
	.col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
	.col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
	.col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }
	.col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
	.col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
	.col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }
	.col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
	.col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
	.col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%;
    }
	.col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .col-xl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
	.col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-xl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
	.col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.btn {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 0;
    font-size: .825rem;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: .375rem .75rem;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.btn-success {
    color: #ffffff;
    background: #00bb42;
}
.btn-primary {
  color: #ffffff;
  background: #007ae1; 
}
.btn-primary:hover {
    color: #ffffff;
    background: #0074d6; 
}
.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 122, 225, 0.3);
    background: #0066bd; 
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #007ae1; 
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background: #0066bd; 
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 122, 225, 0.3); 
}
h3 {
    font-size: 1.5rem;
    margin-bottom: .5rem;
}
h5 {
    font-size: 1rem;
    margin-bottom: .3rem;
}
h6 {
    font-size: .85rem;
    margin-bottom: .2rem;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin-top: 0;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
}
.text-primary {
  color: #007ae1 !important; 
}
.text-secondary {
  color: #ff3e61 !important; 
}
.text-light {
  color: #dadeea !important; 
}
.text-dark {
  color: #616265 !important; 
}
.text-success {
  color: #00bb42 !important; 
}
.text-info {
  color: #007ae1 !important; 
}
.text-warning {
  color: #ffbf05 !important; 
}
.text-danger {
  color: #ff3e3e !important; 
}
.text-grey {
  color: #5a5a5a !important; 
}
.text-muted {
  color: #9fa8b9 !important; 
}
.btn-secondary {
  color: #ffffff;
  background: #ff3e61; 
}
.btn-secondary:hover {
    color: #ffffff;
    background: #ff244c; 
}
.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 96, 0.25);
    background: #ff0a37; 
}
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #ff3e61; 
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background: #ff0a37; 
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 62, 96, 0.25); 
}
.alert.alert-danger {
    background: #ff3e3e;
}
.alert.alert-success {
    background: #00bb42;
}
.alert.alert-info {
    background: #007ae1;
}
.alert {
    position: relative;
    margin-bottom: .5rem;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 0;
    padding: .75rem 1rem;
    font-size: .8rem;
    color: #ffffff;
    display: flex;
    align-items: center;
}
.fade {
    transition: opacity .15s linear;
}
.alert.alert-dismissible .close {
    padding: .6rem 1rem;
    font-weight: 600;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.close {
    text-shadow: 0 1px 0 #000000;
    float: right;
    font-size: 1.5rem;
    line-height: 1;
    opacity: .5;
}
.authentication {
    background-image: linear-gradient(to top, #0250c5 0%, #d43f8d 100%);
    background-attachment: fixed; 
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
}
body.error-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFE53B;
    background: url('~@/assets/img/login-bg.jpg');
    background-position: center center;
    background-size: cover;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
label {
    margin-bottom: .3rem;
    font-size: .725rem;
    font-weight: 600;
    display: inline-block;
}
</style>
