<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Welcome, Member
                </template>
            </page-header>
        </template>
      <member-meta></member-meta>
    </base-component>
</template>

<script>
import BaseComponent from './ui/BaseComponent.vue';
import MemberMeta from './MemberMeta.vue';
import PageHeader from './layout/ThePageHeader.vue';

export default {
    components: {
        BaseComponent,
        MemberMeta,
        PageHeader
    }
}
</script>

