<template>
    <template v-if="isLoading">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <clip-loader  :color="loaderColor" ></clip-loader>
                </div>
    </template>
    <template v-else>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="card">
                <div class="payments-card">
                    <h6>Amount availble for borrowing</h6>
                    <h4>KES {{ result.max_amount }}</h4>
                    <div class="custom-btn-group mt-2" v-if="result.can_borrow == 'yes'">
                        <button class="btn btn-danger" @click="getDevForm"><i class="icon-credit-card"></i>Apply For Loan</button>
                    </div>
                    <div class="custom-btn-group mt-2">
                        <button class="btn btn-danger" @click="showLoansGuaranteed"><i class="icon-credit-card"></i>Show Loans Guaranteed</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="card">
                <div class="payments-card">
                    <h6>Current Loan Count  {{ result.current_loans_count }}</h6>
                    <h6>Current Loans Guaranteed  {{ result.loans_guaranteed_count }}</h6>
                    <h6>Sum of Guaranteed Loans  {{ result.loans_guaranteed_sum }}</h6>

                    <div class="custom-btn-group mt-2">
                        <button class="btn btn-danger" @click="showGuarantorRequests"><i class="icon-credit-card"></i>Show Guarantor Requests</button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>




<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    components: {
        ClipLoader,
    },
    data() {
        return {
            userId: this.$store.getters.userId,
            result: '',
            error: '',
            isLoading: true,
            loaderColor: 'blue'
        }
    },
    mounted() {
        const actionPayload = {
                userId: this.userId,
           };
        let availableAmount = this.$store.dispatch('getGuarantorSummary', actionPayload);
        availableAmount.then(res => this.result = res.data)
        .then(() => {
            this.isLoading = false;
        })
        .catch(err => console.log(err));
    },
    methods: {
        getDevForm() {
           return  this.$router.push('/dev-loan-application');
        },
        showLoansGuaranteed() {
           return  this.$router.push({ name:'devLoansGuaranteed', params: { userId: this.userId } });
        },
        showGuarantorRequests() {
           return  this.$router.push({ name:'guarantorRequests', params: { userId: this.userId } });
        }
    }
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}
.payments-card {
    padding: 1rem;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 125px;
}
.custom-btn-group {
    display: flex;
    flex-wrap: wrap;
}
.mt-2, .my-2 {
    margin-top: .5rem!important;
}
.custom-btn-group .btn {
    margin: .3rem;
    display: flex;
    align-items: center;
}
.btn-danger {
    color: #ffffff;
    background: #ff3e3e;
}
.btn i {
    vertical-align: middle;
    margin: 0 5px 0 0;
}
.payments-card h6 {
    margin: 0 0 4px 0;
    font-weight: 400;
    font-size: .99rem;
}
.payments-card h4 {
    margin: 0;
    font-weight: 700;
    line-height: 100%;
}
</style>