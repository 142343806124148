<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Targeted Savings Form
                </template>
            </page-header>
        </template>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    
                    <div class="row gutters">
                        <div class="col-xl-4 col-lglg-4 col-md-4 col-sm-4 col-12">
                            <form @submit.prevent="submitForm">
                                <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
										{{ error }}
										<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true" @click="closeAlert">×</span>
										</button>
								</div>
                                <div class="form-group">
                                    <label for="inputAmount">Goal Name</label>
                                    <input type="text" class="form-control" :class="goalNameHasError" @keyup="onGoalNameKeyUp" placeholder="Enter goal name" v-model="goalName">
                                    <template v-for="(error, index) in goalNameValidationError">
                                        <div class="invalid-feedback" v-if="showGoalNameErrors" :key="index">{{ error }}</div>
                                    </template>
                                </div>
                                <div class="form-group">
                                    <label for="inputAmount">Target Amount</label>
                                    <input type="number" class="form-control" :class="amountHasError" @keyup="onAmountKeyUp" placeholder="Enter target amount" v-model="targetAmount">
                                    <template v-for="(error, index) in amountValidationError">
                                        <div class="invalid-feedback" v-if="showAmountErrors" :key="index">{{ error }}</div>
                                    </template>
                                </div>

                                <div class="form-group">
                                <flat-pickr :class="startDateHasError" @change="onStartDateKeyUp" v-model="startDate" 
                                    :config="startDateConfig"                                                          
                                    class="form-control" 
                                    placeholder="Select start date">
                                </flat-pickr>
                                <template v-for="(error, index) in startDateValidationError">
                                        <div class="invalid-feedback" v-if="showStartDateErrors" :key="index">{{ error }}</div>
                                 </template>
                                </div>

                                <div class="form-group">
                                <flat-pickr :class="endDateHasError" @change="onEndDateKeyUp" v-model="endDate" 
                                    :config="endDateConfig"                                                          
                                    class="form-control" 
                                    placeholder="Select end date">
                                </flat-pickr>
                                <template v-for="(error, index) in endDateValidationError">
                                        <div class="invalid-feedback" v-if="showEndDateErrors" :key="index">{{ error }}</div>
                                 </template>
                                </div>
                                
                                <div class="form-group">
                                <button type="submit" class="btn btn-primary mb-2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                            
                </div>
            </div>
        </div>
    </base-component>
</template>

<script>
import BaseComponent from '../ui/BaseComponent.vue';
import PageHeader from '../layout/ThePageHeader.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    components: {
        BaseComponent,
        PageHeader,
        flatPickr
    },
    data() {
        return {
            goalName: '',
            targetAmount: '',
            userId: this.$store.getters.userId,
            projectId: 5,
            error:'',
            amountValidationError: '',
            goalNameValidationError: '',
            startDateValidationError: '',
            endDateValidationError: '',
            startDate: '',
            endDate: '',
            startDateConfig: {
                minDate: "today",
                maxDate: new Date().fp_incr(6) // 1 week from now
            },
            endDateConfig: {
                minDate: new Date().fp_incr(13) // 2 weeks from now so that goal duration is at least 1 week
            }
        }
    },
    methods: {
        async submitForm() {
            const actionPayload = {
                goalName: this.goalName,
                targetAmount: this.targetAmount,
                startDate: this.startDate,
                endDate: this.endDate,
                userId: this.userId,
                projectId: this.projectId
            };

            try {
                await this.$store.dispatch('createTargetedSavings', actionPayload);
                this.$router.push({name: 'member_targeted_savings', params: { userId: this.userId }});
            } catch (err) {
                if (Object.prototype.hasOwnProperty.call(err, 'goal_name')) {
                    this.goalNameValidationError = err.goal_name;
                } if (Object.prototype.hasOwnProperty.call(err, 'target_amount')) {
                    this.amountValidationError = err.target_amount;
                } if (Object.prototype.hasOwnProperty.call(err, 'start_date')) {
                    this.startDateValidationError = err.start_date;
                } if (Object.prototype.hasOwnProperty.call(err, 'end_date')) {
                    this.endDateValidationError = err.end_date;
                } else {
                    console.log(err);
                    this.error = Object.values(err);
                }
            }
        },
        closeAlert() {
            this.error = null;
        },
        onAmountKeyUp() {
            return this.amountValidationError = false
        },
        onGoalNameKeyUp() {
            return this.goalNameValidationError = false
        },
        onStartDateKeyUp() {
            return this.startDateValidationError = false
        },
        onEndDateKeyUp() {
            return this.endDateValidationError = false
        },
    },
    computed: {
        amountHasError() {
            return {
                'is-invalid': this.amountValidationError
            };
        },
        showAmountErrors() {
            return this.amountValidationError;
        },
        goalNameHasError() {
            return {
                'is-invalid': this.goalNameValidationError
            };
        },
        showGoalNameErrors() {
            return this.goalNameValidationError;
        },
        startDateHasError() {
            return {
                'is-invalid': this.startDateValidationError
            };
        },
         showStartDateErrors() {
            return this.startDateValidationError;
        },
        endDateHasError() {
            return {
                'is-invalid': this.endDateValidationError
            };
        },
         showEndDateErrors() {
            return this.endDateValidationError;
        },
    }
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem; 
}
.card .card-header {
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.25rem 1rem 1.25rem; 
}
.card .card-header .card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%; 
}
.card .card-header .card-sub-title {
    margin-top: 1rem;
    font-size: .8rem;
    font-weight: 400;
    color: #9fa8b9;
    line-height: 150%; 
}
.card.primary {
    background: #007ae1;
    color: #ffffff; 
}
.card.secondary {
    background: #ff3e61;
    color: #ffffff; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.card .card-footer {
    background: transparent;
    border-top: 0;
    padding: 1rem 1.25rem; 
}
.card .card-footer .view-all {
    color: rgba(0, 0, 0, 0.4);
    font-size: .8rem; 
}
.card .card-footer .view-all i {
    font-size: 1.2rem;
    vertical-align: middle; 
}
.card .card-footer .view-all:hover {
    color: #007ae1; 
}
.card .card-footer a.view {
    color: rgba(0, 0, 0, 0.4);
    font-size: .825rem;
    color: #007ae1;
    font-weight: 700; 
}
.card .card-footer a.view i {
    font-size: 1.5rem;
    margin: 0 10px;
    vertical-align: middle; 
}
.card .card-link {
    color: #007ae1;
    font-weight: 600; 
}
.card .card-link.primary {
    color: #007ae1; 
}
.card .card-link.secondary {
    color: #ff3e61; 
}
.card .card-img-bottom {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0; 
}
.card .card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 
}
.card.highlight {
    border: 1px solid #00d4fd;
    background: linear-gradient(120deg, #00d4fd 0%, #0065fd 100%); 
}
.card-deck {
  margin-right: -10px;
  margin-left: -10px; 
}
.card-deck .card {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 1.25rem; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.form-group {
    margin: 0 0 1rem 0; 
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c; 
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::-webkit-input-placeholder {
    color: #9fa8b9; 
}
.form-control:-moz-placeholder {
    color: #9fa8b9; 
}
.form-control::-moz-placeholder {
    color: #9fa8b9; 
}
.form-control:-ms-input-placeholder {
    color: #9fa8b9; 
}
.form-control:hover {
    border: 1px solid #007ae1; 
}
.form-control:focus {
    border-color: #007ae1;
    box-shadow: none;
    background: #ffffff;
    color: #2e323c; 
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f5f6fa; 
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.gutters > .col, .gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}
label {
    margin-bottom: .3rem;
    font-size: .725rem;
    font-weight: 600;
    display: inline-block;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #ff3e3e;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block;
}
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>