import { createRouter, createWebHistory } from 'vue-router'

import store from './store/index.js';
import Register from './components/auth/Register.vue';
import Login from './components/auth/Login.vue';
import Dashboard from './components/Dashboard.vue';
import DevLoanForm from './components/members/DevLoanForm.vue';
import UniflexForm from './components/members/UniflexForm.vue';
import AccountSettings from './components/members/AccountSettings.vue';
import Wallet from './components/members/WalletPage.vue';
import DevLoans from './components/members/DevLoans.vue';
import UniflexLoans from './components/members/UniflexLoans.vue';
import Savings from './components/members/Savings.vue';
import PersonalProjects from './components/members/PersonalProjects.vue';
import UnitrustProjects from './components/members/UnitrustProjects.vue';
import UnitrustUniflexLoans from './components/finance/UnitrustUniflexLoans.vue';
import UnitrustDevLoans from './components/finance/UnitrustDevLoans.vue';
import Disbursements from './components/finance/Disbursements.vue';
import PhoneVerification from './components/auth/PhoneVerification.vue';
import NotFound from './components/NotFound.vue';
import ShambaProject from './components/members/ShambaProject.vue';
import InsuranceProject from './components/members/InsuranceProject.vue';
import WalletConfirmation from './components/members/WalletConfirmation.vue';
import WalletTopup from './components/members/WalletTopup.vue';
import ProjectDeposit from './components/members/ProjectDeposit.vue';
import ProjectDetails from './components/members/ProjectDetails.vue';
import TargetedSavingsForm from './components/members/TargetedSavingsForm.vue';
import TargetedSavings from './components/members/TargetedSavings.vue';
import InsuranceForm from './components/members/InsuranceForm.vue';
import LoansGuaranteed from './components/members/LoansGuaranteed.vue';
import GuarantorRequests from './components/members/GuarantorRequests.vue';
import DevloansForReview from './components/finance/DevloansForReview.vue';
import ForgotPassword from './components/auth/ForgotPassword.vue';
import Shares from './components/members/SharesPage.vue';
import SharePurchaseForm from './components/members/SharePurchaseForm.vue';
import ResetPassword from './components/auth/ResetPassword.vue';
import FinanceProjectDetails from './components/finance/FinanceProjectDetails.vue';
import Members from './components/finance/Members.vue';
import StkPayments from './components/finance/StkPayments.vue';
import C2BPayments from './components/finance/C2BPayments.vue';
import UnitrustLoanSavings from './components/finance/LoanSavings.vue';
import UnitrustShamba from './components/finance/UnitrustShamba.vue';
import UnitrustTargetSavings from './components/finance/UnitrustTargetSavings.vue';
import UnitrustShares from './components/finance/UnitrustShares.vue';
import UnitrustInsurance from './components/finance/UnitrustInsurance.vue';
import UploadedPayments from './components/finance/UploadedPayments.vue';


const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/',
            redirect: '/login'
        },
        {
            path: '/dashboard',
            components: {
                dashboard: Dashboard
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/register',
            components: {
                register: Register
            }
        },
        {
            path: '/verify-phone',
            components: {
                phone_verification: PhoneVerification
            }
        },
        {
            path: '/login',
            name: 'login',
            components: {
                login: Login
            },
            meta: { requiresUnauth: true }
        },
        {
            path: '/forgot-password',
            name: 'forgotPassword',
            components: {
                forgotPassword: ForgotPassword
            },
        },
        {
            path: '/password/reset/:token',
            name: 'resetPassword',
            components: {
                resetPassword: ResetPassword
            },
        },
        {
            path: '/dev-loan-application',
            name: 'devloan-form',
            components: {
                devloanform: DevLoanForm
            },
            meta: { requiresAuth: true },
        },
        {
            path: '/uniflex-loan-application',
            name: 'uniflex-form',
            components: {
                uniflexform: UniflexForm
            },
            meta: { requiresAuth: true },
        },
        {
            path: '/account-settings',
            components: {
                accountsettings: AccountSettings
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/wallet/:id',
            name: 'member-wallet',
            components: {
                wallet: Wallet
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/wallet-topup',
            name: 'member-wallet-topup',
            components: {
                walletTopup: WalletTopup
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/wallet-confirmation',
            name: 'walletConfirmation',
            components: {
                walletConfirmation: WalletConfirmation
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/shares/:id',
            name: 'member-shares',
            components: {
                memberShares: Shares
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/shares/purchase',
            name: 'shares-purchase-form',
            components: {
                sharesPurchase: SharePurchaseForm
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/dev-loans/:id',
            name: 'devloans',
            components: {
                devloans: DevLoans
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/dev-loans-guaranteed/:id',
            name: 'devLoansGuaranteed',
            components: {
                devloansGuaranteed: LoansGuaranteed
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/dev-loans-to-be-guaranteed/:id',
            name: 'guarantorRequests',
            components: {
                guarantorRequests: GuarantorRequests
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/uniflex-loans/:id',
            name: 'uniflexloans',
            components: {
                uniflexloans: UniflexLoans
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/member-shamba/:id',
            name: 'member_shamba',
            components: {
                shambaProject: ShambaProject
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/member-insurance/:id',
            name: 'member_insurance',
            components: {
                insuranceProject: InsuranceProject
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/member-insurance-form/:id',
            name: 'member_insurance_form',
            components: {
                insuranceForm: InsuranceForm
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/targeted-savings/:id',
            name: 'member_targeted_savings',
            components: {
                targetedSavings: TargetedSavings
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/targeted-savings-form/:id',
            name: 'targeted_savings_form',
            components: {
                targetedSavingsForm: TargetedSavingsForm
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/project-deposit/:project_code',
            name: 'project_deposit',
            components: {
                projectDeposit: ProjectDeposit
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/project/:project_code',
            name: 'project_details',
            components: {
                projectDetails: ProjectDetails
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/savings/:id',
            name: 'savings',
            components: {
                savings: Savings
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/target-savings-form',
            name: 'target-savings',
            components: {
                targetSavingsForm: TargetedSavingsForm
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/personal-projects',
            components: {
                personalprojects: PersonalProjects
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-projects',
            components: {
                unitrustprojects: UnitrustProjects
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-uniflex',
            name: 'unitrustUniflexLoans',
            components: {
                unitrustuniflex: UnitrustUniflexLoans
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-dev-loans',
            name: 'unitrustDevLoans',
            components: {
                unitrust_dev_loans: UnitrustDevLoans
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-loan-savings',
            name: 'unitrustLoanSavings',
            components: {
                unitrust_loan_savings: UnitrustLoanSavings
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-stk-payments',
            name: 'unitrustStkPayments',
            components: {
                unitrust_stk_payments: StkPayments
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-c2b-payments',
            name: 'unitrustC2BPayments',
            components: {
                unitrust_c2b_payments: C2BPayments
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-members',
            name: 'unitrustMembers',
            components: {
                unitrust_members: Members
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-shamba',
            name: 'unitrustShamba',
            components: {
                unitrust_shamba: UnitrustShamba
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-target-savings',
            name: 'unitrustTargetSavings',
            components: {
                unitrust_target_savings: UnitrustTargetSavings
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-shares',
            name: 'unitrustShares',
            components: {
                unitrust_shares: UnitrustShares
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-insurance',
            name: 'unitrustInsurance',
            components: {
                unitrust_insurance: UnitrustInsurance
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-uploaded-payments',
            name: 'unitrustUploadedPayments',
            components: {
                unitrust_uploaded_payments: UploadedPayments
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/unitrust-project-details/:project_code',
            name: 'unitrustProjectDetails',
            components: {
                unitrust_project_details: FinanceProjectDetails
            },
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/devloans/review',
            name: 'devloansForApproval',
            components: {
                devloansForReview: DevloansForReview
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/disbursements',
            components: {
                disbursements: Disbursements
            },
            meta: { requiresAuth: true }
        },
        {
            path: '/notfound',
            components: {
                notfound: NotFound
            }
        }
    ],
    linkActiveClass: 'active',
});

router.beforeEach(function(to, from, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/dashboard');
    } else {
        next();
    }
});

export default router;