<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Shares purchase form
                </template>
            </page-header>
        </template>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    
                    <div class="row gutters">
                        <div class="col-xl-4 col-lglg-4 col-md-4 col-sm-4 col-12">
                            <form @submit.prevent.once ="submitForm">
                                <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
										{{ error }}
										<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true" @click="closeAlert">×</span>
										</button>
								</div>
                                <div class="form-group">
                                    <label for="inputAmount"> Amount to spend</label>
                                    <input type="number" class="form-control" :class="amountHasError" @keyup="onAmountKeyUp" placeholder="Enter amount to spend" v-model="amount">
                                    <template v-for="(error, index) in amountValidationError">
                                        <div class="invalid-feedback" v-if="showAmountErrors" :key="index">{{ error }}</div>
                                    </template>
                                </div>
                                <p> Number of Shares {{calculateShares}}</p>
                               
                                <div class="row gutters">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline1" value="wallet" v-model="picked" name="customRadioInline1" class="custom-control-input" @click="onPaymentMethodSelect" :class="paymentMethodHasError">
                                                <label class="custom-control-label" for="customRadioInline1">Wallet</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline2" value="mpesa" v-model="picked" name="customRadioInline2" class="custom-control-input" @click="onPaymentMethodSelect"  :class="paymentMethodHasError">
                                                <label class="custom-control-label" for="customRadioInline2">Mpesa</label>
                                            </div>
                                            <template v-for="(error, index) in paymentMethodValidationError">
                                                <div class="invalid-feedback" v-if="showPaymentMethodErrors" :key="index">{{ error }}</div>
                                            </template>
                                        </div>
                                </div>
                                
                                <div class="form-group" v-if="picked == 'mpesa'">
                                    <label for="phone">Phone</label>
                                    <input type="number" class="form-control" id="msisdn" placeholder="Phone Number" v-model="msisdn">
                                </div>
                                    
                                <div class="form-group">
                                <button type="submit" class="btn btn-primary mb-2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                            
                </div>
            </div>
        </div>
    </base-component>
</template>

<script>
import BaseComponent from '../ui/BaseComponent.vue';
import PageHeader from '../layout/ThePageHeader.vue';

export default {
    components: {
        BaseComponent,
        PageHeader,
    },
    data() {
        return {
            amount: '',
            no_of_shares: '',
            shareValue: process.env.VUE_APP_SHARE_VALUE,
            userId: this.$store.getters.userId,
            msisdn: this.$store.getters.phoneNumber,
            error: '',
            amountValidationError: '',
            paymentMethodValidationError: '',
            picked: null,
            walletData: '',
        }
    },
    mounted() {
        const actionPayload = {userId: this.userId};
        let wallet = this.$store.dispatch('getWallet', actionPayload);
        wallet.then(res => this.walletData = res)
        .catch(err => console.log(err));
    },
    methods: {
        async submitForm() {
            const actionPayload = {
                amount: this.amount,
                no_of_shares: this.calculateShares,
                userId: this.userId,
                msisdn: this.msisdn,
                shareValue: this.shareValue,
                picked: this.picked
            };

            try {
                await this.$store.dispatch('buyShares', actionPayload);
                this.$router.push({name: 'member-shares', params: { id: this.userId }});
            } catch (err) {
                const amountPaidError = Object.prototype.hasOwnProperty.call(err, 'amount_paid');
                const paymentMethodError = Object.prototype.hasOwnProperty.call(err, 'payment_mode');
                if (amountPaidError) {
                    this.amountValidationError = err.amount_paid;
                } 
                if (paymentMethodError) {
                    this.paymentMethodValidationError = err.payment_mode;
                } 
                if (!amountPaidError && !paymentMethodError) {
                    console.log(err);
                    this.error = err;
                }
            }
        },
        closeAlert() {
            this.error = null;
        },
        onAmountKeyUp() {
            return this.amountValidationError = false
        },
        onPaymentMethodSelect() {
            return this.paymentMethodValidationError = false
        },
    },
    computed: {
        amountHasError() {
            return {
                'is-invalid': this.amountValidationError
            };
        },
        showAmountErrors() {
            return this.amountValidationError;
        },
        paymentMethodHasError() {
            return {
                'is-invalid': this.paymentMethodValidationError
            };
        },
        showPaymentMethodErrors() {
            return this.paymentMethodValidationError;
        },
        amountToSpend() {
            return this.amount;
        },
        calculateShares() {
            return Math.floor(this.amount/this.shareValue);
        }
    }
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem; 
}
.card .card-header {
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.25rem 1rem 1.25rem; 
}
.card .card-header .card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%; 
}
.card .card-header .card-sub-title {
    margin-top: 1rem;
    font-size: .8rem;
    font-weight: 400;
    color: #9fa8b9;
    line-height: 150%; 
}
.card.primary {
    background: #007ae1;
    color: #ffffff; 
}
.card.secondary {
    background: #ff3e61;
    color: #ffffff; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.card .card-footer {
    background: transparent;
    border-top: 0;
    padding: 1rem 1.25rem; 
}
.card .card-footer .view-all {
    color: rgba(0, 0, 0, 0.4);
    font-size: .8rem; 
}
.card .card-footer .view-all i {
    font-size: 1.2rem;
    vertical-align: middle; 
}
.card .card-footer .view-all:hover {
    color: #007ae1; 
}
.card .card-footer a.view {
    color: rgba(0, 0, 0, 0.4);
    font-size: .825rem;
    color: #007ae1;
    font-weight: 700; 
}
.card .card-footer a.view i {
    font-size: 1.5rem;
    margin: 0 10px;
    vertical-align: middle; 
}
.card .card-link {
    color: #007ae1;
    font-weight: 600; 
}
.card .card-link.primary {
    color: #007ae1; 
}
.card .card-link.secondary {
    color: #ff3e61; 
}
.card .card-img-bottom {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0; 
}
.card .card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 
}
.card.highlight {
    border: 1px solid #00d4fd;
    background: linear-gradient(120deg, #00d4fd 0%, #0065fd 100%); 
}
.card-deck {
  margin-right: -10px;
  margin-left: -10px; 
}
.card-deck .card {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 1.25rem; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.form-group {
    margin: 0 0 1rem 0; 
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c; 
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::-webkit-input-placeholder {
    color: #9fa8b9; 
}
.form-control:-moz-placeholder {
    color: #9fa8b9; 
}
.form-control::-moz-placeholder {
    color: #9fa8b9; 
}
.form-control:-ms-input-placeholder {
    color: #9fa8b9; 
}
.form-control:hover {
    border: 1px solid #007ae1; 
}
.form-control:focus {
    border-color: #007ae1;
    box-shadow: none;
    background: #ffffff;
    color: #2e323c; 
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f5f6fa; 
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.gutters > .col, .gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}
label {
    margin-bottom: .3rem;
    font-size: .725rem;
    font-weight: 600;
    display: inline-block;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #ff3e3e;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block;
}
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
</style>