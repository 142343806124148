import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import rootMutations from './mutations.js';
import rootActions from './actions.js';
import rootGetters from './getters.js';
import membersModule from './modules/members/index.js';
import officialsModule from './modules/officials/index.js';
import authModule from './modules/auth/index.js';

const store = createStore({
    plugins: [createPersistedState()],

    modules: {
        members: membersModule,
        officials: officialsModule,
        auth: authModule
    },
    state() {
        return {
            userId: null,
            token: null,
            phoneNumber: null,
            userType: 1,
            toggleSidebar: 'toggled sidebar-hovered',
        };
    },
    mutations: rootMutations,
    actions: rootActions,
    getters: rootGetters
});

export default store;