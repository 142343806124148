import axios from 'axios';
export default {
    async login(context, payload) {
        return context.dispatch('auth', {
            ...payload,
            mode: 'login'
        });
    },
    async signup(context, payload) {
        return context.dispatch('auth', {
            ...payload,
            mode: 'signup'
        });
    },
    async auth(context, payload) {
        const mode = payload.mode;

        if (mode === 'login') {
            const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + 'api/login', {
                email: payload.email,
                password: payload.password
            });

            const responseData = response.data;

            if (responseData.status_code == 422) {
                const error = responseData.error;
                throw error;
            }

            localStorage.setItem('token', responseData.data.token);
            localStorage.setItem('userId', responseData.data.id);

            context.commit('setUser', {
                token: responseData.data.token,
                userId: responseData.data.id,
                phoneNumber: responseData.data.phone_number,
                userType: responseData.data.user_type,
            });
            return responseData;
        } else {
            const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + 'api/register', {
                email: payload.email,
                password: payload.password,
                phone_number: payload.phone,
                confirm_password: payload.confirmPassword,
                first_name: payload.firstName,
                last_name: payload.lastName,
                user_type: payload.userType
            });

            const responseData = response.data;

            if (responseData.status_code == 422) {
                const error = responseData.error;
                throw error;
            }

            localStorage.setItem('token', '');
            localStorage.setItem('userId', responseData.data.member.user_id);
            localStorage.setItem('phoneNumber', responseData.data.phone_number);
            localStorage.setItem('userType', responseData.data.user_type);

            context.commit('setUser', {
                token: '',
                userId: responseData.data.member.user_id,
                phoneNumber: responseData.data.phone_number,
                userType: responseData.data.user_type,
            });
        }
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('userType');

        context.commit('setUser', {
            token: null,
            userId: null,
            phoneNumber: null,
            userType: null,
        })
        delete axios.defaults.headers.common['Authorization'];
    },

    async verify(context, payload) {
        const userId = localStorage.getItem('userId');
        const phoneNumber = localStorage.getItem('phoneNumber');
        const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + 'api/verify_phone', {
            user_id: userId,
            phone_number: phoneNumber,
            otp: payload.otp
        });

        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        localStorage.setItem('token', responseData.data.token);
        context.commit('setUser', {
            token: responseData.data.token,
            userId: responseData.data.id,
            phoneNumber: responseData.data.phone_number
        });
        return responseData;
    },
    async resendOtp(context) {
        const userId = localStorage.getItem('userId');
        const phoneNumber = localStorage.getItem('phoneNumber');
        const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + 'api/resend_otp', {
            user_id: userId,
            phone_number: phoneNumber,
        });

        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }

        localStorage.setItem('token', responseData.data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + responseData.data.token;
        context.commit('setUser', {
            token: '',
            userId: responseData.data.id,
            phoneNumber: responseData.data.phone_number
        });
    },
    async checkToken() {
        const response = await axios.get(process.env.VUE_APP_USERS_ENDPOINT + 'api/user');
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async forgotPassword(_, payload) {
        const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + 'api/password/email', {
            email: payload.email
        });

        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async resetPassword(_, payload) {
        const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + `api/password/reset/${payload.token}`, {
            email: payload.email,
            password: payload.password,
            password_confirmation: payload.confirm_password,
            token: payload.token
        });

        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    toggleSidebar(context, payload) {
        context.commit('setSidebar', {
            toggleSidebar: payload.sideBarValue,
        })
    }
};