<template>
    <!-- Header start -->
    <header class="header">
        <div class="toggle-btns">
            <a id="toggle-sidebar" href="#">
                <i class="icon-menu"></i>
            </a>
            <a id="pin-sidebar" href="#" @click="toggleSidebar">
                <i class="icon-menu"></i>
            </a>
		</div>
        <div class="header-items">
            <!-- Header actions start -->
			<ul class="header-actions">
                <li class="dropdown user-settings">
					<a href="#" id="userSettings" data-toggle="dropdown" aria-haspopup="true">
						<img src="@/assets/img/user2.png" class="user-avatar" alt="Avatar">
					</a>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="userSettings">
                        <div class="header-profile-actions">
                            <!-- <div class="header-user-profile">
                                <div class="header-user">
                                    <img src="@/assets/img/user2.png" alt="Admin Template">
                                </div>
                                <h5>Bernard </h5>
                                <p>Super Admin</p>
                            </div> -->
                            <!-- <a href="user-profile.html"><i class="icon-user1"></i> My Profile</a> -->
                            <!-- <a href="account-settings.html"><i class="icon-settings1"></i> Account Settings</a> -->
                            <a @click="logout" ><i class="icon-log-out1"></i> Sign Out</a>
                        </div>
                    </div>
                </li>
            </ul>						
            <!-- Header actions end -->
        </div>
    </header>
    <!-- Header end -->
</template>

<script>
export default {
    data() {
        return {
            sideBarValue: '',
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/login');
        },
        toggleSidebar() {
            // if (window.innerWidth > 768) {
            //     if (this.$store.getters.toggleSidebar == 'pinned sidebar-hovered') {
            //         this.sideBarValue = ''
            //     } else {
            //         this.sideBarValue = 'pinned sidebar-hovered'
            //     }
            // } else {
                if (this.$store.getters.toggleSidebar == 'toggled') {
                    this.sideBarValue = ''
                } else {
                    this.sideBarValue = 'toggled'
                }
            // }
            
            const actionPayload = {
                sideBarValue: this.sideBarValue
           };
            this.$store.dispatch('toggleSidebar',actionPayload);
        }
    },
}
</script>

<style scoped>
.header {
    padding: .475rem 1.5rem .475rem 1.5rem;
    z-index: 100;
    position: relative;
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: padding-left 0.3s ease;
    -moz-transition: padding-left 0.3s ease;
    -ms-transition: padding-left 0.3s ease;
    -o-transition: padding-left 0.3s ease;
    transition: padding-left 0.3s ease;
    background: #ffffff; 
}
.header-items {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
}
.header-actions {
    display: flex;
    align-items: center; 
}
.header-actions > li {
    margin: 0;
    position: relative; 
}
.header-actions > li > a {
    padding: 1rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center; 
}
.header-actions > li > a > i {
    font-size: 1.2rem;
    color: #9fa8b9; 
}
.header-actions > li > a .count-label {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 9px;
    height: 9px;
    border: 2px solid #ff3e3e;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    animation: pulse-red 2s infinite;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out; 
}
.header-actions > li > a .count-label.blue {
    border: 2px solid #007ae1;
    animation: pulse-blue 2s infinite;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out; 
}
.header-actions > li > a .count-label.green {
    border: 2px solid #00bb42;
    animation: pulse-green 2s infinite;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out; 
}
.header-actions > li:hover, .header-actions > li:focus {
    background: #007ae1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px; 
}
.header-actions > li:hover i, .header-actions > li:focus i {
    color: #ffffff; 
}
.header-actions > li:hover .dropdown-menu, .header-actions > li:focus .dropdown-menu {
    margin-top: 0;
    display: block; 
}
.header-actions > li:hover:after, .header-actions > li:focus:after {
    content: '';
    background: url(/img/lines.svg) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 250%;
    background-position: right center; 
}
.header-actions > li.balance {
    position: relative;
    padding: 1rem 2rem;
    overflow: hidden;
    color: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #007ae1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column; 
}
.header-actions > li.balance:after {
    content: '';
    background: url(/img/lines.svg) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 150%;
    background-position: center center; 
}
.header-actions > li.balance h3 {
    margin: 0; 
}
.header-actions > li.balance h6 {
    margin: 0;
    font-weight: 100; 
}
.header-actions > li.balance:hover, .header-actions > li.balance:focus {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px; 
}
.header-actions > li.user-settings > a {
    padding: 0;
    margin: 0 0 0 15px; 
}
.header-actions > li.user-settings > a > img.user-avatar {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px; 
}
.header-actions > li.user-settings:hover, .header-actions > li.user-settings:focus {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: transparent !important; 
}
.header-actions > li.user-settings:hover:after, .header-actions > li.user-settings:focus:after {
    background: transparent !important; 
}
.header-actions .dropdown-menu {
    -webkit-border-radius: 5px 5px 30px 30px;
    -moz-border-radius: 5px 5px 30px 30px;
    border-radius: 5px 5px 30px 30px; 
}
img {
    vertical-align: middle;
    border-style: none;
}
.header-profile-actions {
    margin: 0;
    padding: .8rem 0; 
}
.header-profile-actions .header-user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: .5rem;
    color: #2e323c; 
}
.header-profile-actions .header-user-profile .header-user {
    margin-bottom: .5rem; 
}
.header-profile-actions .header-user-profile .header-user img {
    width: 4rem;
    height: 4rem;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px; 
}
.header-profile-actions .header-user-profile p {
    background: #ff3e3e;
    color: #ffffff;
    font-size: .725rem;
    padding: 2px 10px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px; 
}
.header-profile-actions a {
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    font-size: .8rem;
    position: relative; 
}
.header-profile-actions a i {
      font-weight: 700;
      margin-right: .5rem;
      font-size: 1rem;
      color: #9fa8b9 !important;
      vertical-align: text-top; 
}
.header-profile-actions a:hover {
    background: #007ae1;
    color: #ffffff; 
}
.header-profile-actions a:hover:after {
    content: '';
    background: url(/img/lines.svg) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 150%;
    background-position: center center; 
}
.header-profile-actions a:hover i {
    color: #ffffff !important; 
}
.header-profile-actions a:last-child {
    border-bottom: 0; 
}
.dropdown-menu {
    border: 0;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    box-shadow: 20px 30px 30px rgba(0, 0, 0, 0.2);
    width: 11rem;
    font-size: .75rem;
    background: #ffffff; 
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    color: #212529;
    text-align: left;
    list-style: none;
}
.dropdown-menu:before {
    position: absolute;
    top: -9px;
    left: 12px;
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #ffffff; 
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-menu.dropdown-menu-right:before {
    right: 15px;
    left: auto;
    top: -9px; 
}
.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}
.header .toggle-btns #toggle-sidebar {
    display: none;
}
.header .toggle-btns #pin-sidebar, .header .toggle-btns #toggle-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    background: #f5f6fa;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; 
}
.header .toggle-btns #pin-sidebar i, .header .toggle-btns #toggle-sidebar i {
    font-size: 1rem;
    font-weight: 600;
    color: #007ae1; 
}
.header .toggle-btns #pin-sidebar:hover, .header .toggle-btns #toggle-sidebar:hover {
    background: #007ae1; 
}
.header .toggle-btns #pin-sidebar:hover i, .header .toggle-btns #toggle-sidebar:hover i {
    color: #ffffff; 
}
.header .toggle-btns #pin-sidebar:hover:after, .header .toggle-btns #toggle-sidebar:hover:after {
    content: '';
    background: url(/img/lines.svg) no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: 150%;
    background-position: center center; 
}
.header .toggle-btns #toggle-sidebar {
    display: none; 
}
.header.primary {
    background: #007ae1; 
}
.header.primary .header-actions > li > a > i {
    color: #ffffff; 
}
.header.primary .header-actions > li:hover {
    background: rgba(0, 0, 0, 0.1); 
}
.header.primary .custom-search .search-query {
    border: 1px solid #ffffff; 
}
.header.primary .toggle-btns #pin-sidebar:hover {
    background: rgba(255, 255, 255, 0.9); }
    .header.primary .toggle-btns #pin-sidebar:hover i {
    color: #007ae1; 
}
.header.primary .toggle-btns #toggle-sidebar:hover {
    background: rgba(255, 255, 255, 0.9); 
}
.header.primary .toggle-btns #toggle-sidebar:hover i {
    color: #007ae1; 
}
@media screen and (max-width: 768px){
.page-wrapper.toggled .header {
    padding-left: 245px !important;
}
}
</style>