export default {
    setUser(state, payload) {
        state.token = payload.token;
        state.userId = payload.userId;
        state.phoneNumber = payload.phoneNumber;
        state.userType = payload.userType;
    },
    setUserId(state, payload) {
        state.userId = payload.userId;
    },
    setSidebar(state, payload) {
        state.toggleSidebar = payload.toggleSidebar;
    }
};