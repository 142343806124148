<template>
    <page-wrapper>
        <side-bar></side-bar>
        <page-content>
            <the-header>
            </the-header>
            <main-container>
                <slot name="page-header"></slot>
                    <row-gutter>
                        <slot></slot>
                    </row-gutter>
            </main-container>
            <the-footer></the-footer>
        </page-content>
    </page-wrapper>
</template>

<script>
import PageWrapper from '../layout/ThePageWrapper.vue';
import SideBar from '../layout/TheSideBar.vue';
import PageContent from '../layout/ThePageContent.vue';
import TheHeader from '../layout/TheHeader.vue';
import MainContainer from '../layout/TheMainContainer.vue';
import RowGutter from '../layout/TheRowGutters.vue';
import TheFooter from '../layout/TheFooter.vue';

export default {
    components: {
        PageWrapper,
        SideBar,
        PageContent,
        MainContainer,
        RowGutter,
        TheHeader,
        TheFooter
    }
}
</script>