<template>
        <div class="container">
            <form @submit.prevent="submit">
                <div class="row justify-content-md-center">
                    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                        <div class="login-screen">
                            <div class="login-box">
                                <a href="#" class="login-logo">UniTrust</a>
                                <h5>Enter the one time password you received via sms</h5>
                                <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
										{{ error }}
										<button type="button" class="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true" @click="closeAlert">×</span>
										</button>
									</div>
                                <div class="form-group">
                                    <input type="number" class="form-control" placeholder="OTP" v-model="otp" />
                                </div>
                                <div class="actions mb-4">
                                    <button type="submit" class="btn btn-success">Submit</button>
                                </div>
                                <div class="actions align-left">
                                    <a @click="resendOtp" class="btn btn-info ml-0">Resend OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            otp: '',
            error: ''
        }
    },
    methods: {
        async submit() {
            const actionPayload = {
                otp: this.otp
           };

            try {
                let response = await this.$store.dispatch('verify', actionPayload);
                const redirectUrl = '/' + (this.$route.query.redirect || 'dashboard');
                this.$router.replace(redirectUrl);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
            } catch (err) {
                this.error = err;
            }
        },
        async resendOtp() {
            try {
                await this.$store.dispatch('resendOtp');
            } catch (err) {
                this.error = err;
            }
        },
        closeAlert() {
            this.error = null;
        },
    }
        
}
</script>

<style scoped>
div {
    display: block;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
form {
    display: block;
    margin-top: 0em;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.justify-content-md-center {
    justify-content: center!important;
}
.login-screen {
    position: relative;
    background: #ffffff;
    border: 0;
    border-radius: 20px;
    margin: 60px auto;
    min-width: 320px;
    max-width: 320px;
    color: #000000;
}
.login-screen .login-box {
    padding: 1.5rem;
}
.login-screen .login-logo {
    margin: 1rem 0 2rem 0;
    display: inline-flex;
    font-size: 2rem;
    font-weight: 700;
    color: #007ae1;
}
.login-screen .form-control {
    color: #000000 !important;
    background: #ffffff;
    border: 1px solid #cfd1d8;
}
.login-screen .actions {
    margin-bottom: .5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}
.form-group {
    margin: 0 0 1rem 0;
}
.form-control {
    border-radius: 2px;
    font-size: .825rem;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button {
    border-radius: 0;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
}
.login-screen .custom-control {
    min-height: auto;
}
.login-screen .actions .btn {
    margin-left: 10px;
}
.login-screen .forgot-pwd {
    margin-bottom: .5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.login-screen .actions.align-left {
    justify-content: flex-start;
}
.login-screen .forgot-pwd a {
    color: gray;
    font-size: .8rem;
}
.btn {
    border-radius: 3px;
    border: 0;
    font-size: .825rem;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: .375rem .75rem;
    user-select: none;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-success {
    color: #ffffff;
    background: #00bb42;
}
.btn-info {
    color: #ffffff;
    background: #007ae1;
}
.ml-0, .mx-0 {
    margin-left: 0!important;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
a {
    text-decoration: none;
}
.login-screen .login-box h5 {
    margin: 0 0 1rem 0;
    font-size: .85rem;
    line-height: 150%;
    font-weight: 400;
}
</style>
