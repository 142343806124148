<template>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="info-stats2">
                <div class="info-icon info">
                    <i class="icon-activity"></i>
                </div>
                <div class="sale-num">
                    <h3>{{ Math.floor(totalSavings) }}</h3>
                    <p>Savings</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="info-stats2">
                <div class="info-icon danger">
                    <i class="icon-activity"></i>
                </div>
                <div class="sale-num">
                    <h3>{{ uniflexTotal }}</h3>
                    <p>Uniflex Loans Balance</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="info-stats2">
                <div class="info-icon warning">
                    <i class="icon-activity"></i>
                </div>
                <div class="sale-num">
                    <h3>{{ devloanTotal }}</h3>
                    <p>Dev loans Balance</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="info-stats2">
                <div class="info-icon success">
                    <i class="icon-activity"></i>
                </div>
                <div class="sale-num">
                    <h3>{{ Math.floor(walletBalance) }}</h3>
                    <p>Wallet</p>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    data() {
        return {
            userId: this.$store.getters.userId,
            totalSavings: '',
            uniflexTotal: '',
            devloanTotal: '',
            walletBalance: '',
            error: ''
        }
    },
    mounted() {
        const actionPayload = {
                userId: this.userId,
           };
        let totalSavings = this.$store.dispatch('savingsTotal', actionPayload);
        totalSavings.then(res => this.totalSavings = res.data.data).catch(err => console.log(err));

        let totalUniflex= this.$store.dispatch('memberUniflexBalance', actionPayload);
        totalUniflex.then(res => this.uniflexTotal = res.data.data).catch(err => console.log(err));
        
        let totalDevloan= this.$store.dispatch('memberDevLoanBalance', actionPayload);
        totalDevloan.then(res => this.devloanTotal = res.data.data).catch(err => console.log(err));

        let walletBalance = this.$store.dispatch('walletMeta', actionPayload);
        walletBalance.then(res => this.walletBalance = res.balance)
        .catch(err => console.log(err));
    },
}
</script>

<style scoped>
.info-stats2 {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 1.2rem 1rem;
}
.info-stats2 .info-icon {
    height: 50px;
    width: 50px;
    background: #f5f6fa;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    text-align: center; }
.info-stats2 .info-icon i {
    font-size: 1.4rem;
    color: #ffffff; 
}
.info-stats2 .info-icon.info {
    background: #007ae1; 
}
.info-stats2 .info-icon.warning {
    background: #ffbf05; 
}
.info-stats2 .info-icon.danger {
    background: #ff3e3e; 
}
.info-stats2 .info-icon.success {
    background: #00bb42; 
}
.info-stats2 .sale-num h3 {
    color: #2e323c;
    margin: 0; 
}
.info-stats2 .sale-num p {
    margin: 0;
    color: #9fa8b9; 
}

</style>