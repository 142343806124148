<template>
     <div class="row">
        <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="basicExample_info" role="status" aria-live="polite">
                <template v-if="total > 0">
                Showing {{from}} to {{to}} of {{total}} entries
                </template>
                <template v-else>
                    No records to display
                </template>
                </div>
        </div>
        <template v-if="total > 0">
        <div class="col-sm-12 col-md-7">
            <div class="dataTables_paginate paging_simple_numbers" id="basicExample_paginate">
                <ul class="pagination pagination-sm">
                    <li class="paginate_button page-item previous " id="basicExample_previous" :class="{disabled:isInFirstPage}">
                    <a href="#" aria-controls="basicExample" data-dt-idx="0" tabindex="0" class="page-link"
                    @click="onClickPreviousPage"
                    :disabled="isInFirstPage"
                    aria-label="Go to previous page"
                    >
                    Previous
                    </a>
                    </li>
                    <li class="paginate_button page-item" v-for="page in pages" :key="page.name" :class="{ active: isPageActive(page.name)}">
                        <a href="#" aria-controls="basicExample" data-dt-idx="1" tabindex="0" class="page-link" 
                        @click="onClickPage(page.name)"
                        :disabled="page.isDisabled"
                        :class="{ active: isPageActive(page.name) }"
                        :aria-label="`Go to page number ${page.name}`">{{page.name}}</a>
                    </li>
                    
                    <li class="paginate_button page-item next" id="basicExample_next" :class="{disabled:isInLastPage}">
                        <a href="#" aria-controls="basicExample" data-dt-idx="8" tabindex="0" class="page-link"
                        @click="onClickNextPage"
                        :disabled="isInLastPage"
                        aria-label="Go to next page"
                        >
                        Next
                        </a>
                    </li>
                    <li class="paginate_button page-item next" id="basicExample_next" :class="{disabled:isInLastPage}">
                        <a href="#" aria-controls="basicExample" data-dt-idx="8" tabindex="0" class="page-link"
                        @click="onClickLastPage"
                        :disabled="isInLastPage"
                        aria-label="Go to last page"
                        >
                        Last
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </template>
    </div> 
</template>
<script>
export default {
    props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    from:{
        type: Number,
        required: true
    },
    to:{
        type: Number,
        required: true
    },
    total:{
        type: Number,
        required: true
    }
  },
  computed: {
    startPage() {
        if (this.currentPage === 1) {
            return 1;
        }

        if (this.currentPage === this.totalPages) { 
            return this.totalPages - this.maxVisibleButtons + 1;
        }

        return this.currentPage - 1;

    },
    endPage() {
        return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
        const range = [];

        for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
            range.push({
            name: i,
            isDisabled: i === this.currentPage 
            });
        }

        return range;
    },
    isInFirstPage() {
        return this.currentPage === 1;
    },
    isInLastPage() {
        return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
        this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
        this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
        this.$emit('pagechanged', page);
    },
    onClickNextPage() {
        this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
        this.$emit('pagechanged', this.totalPages);    
    },
    isPageActive(page) {
        return this.currentPage === page;
    },
  
  }
}
</script>
<style scoped>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
}
div.dataTables_wrapper div.dataTables_info {
    color: #5a8dee;
    font-size: .775rem;
    margin: 10px 0 0 0;
    padding: 0 0 15px 0;
    white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_paginate {
    white-space: nowrap;
    text-align: right;
    margin: 10px 0 0 0;
    padding: 0 0 10px 0px;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
}
.dataTables_paginate .pagination .page-item.disabled .page-link {
    background: transparent;
}
.pagination .page-item.disabled .page-link {
    opacity: 0.4;
    background: #007ae1;
    color: rgba(255, 255, 255, 0.4);
}
.dataTables_paginate .pagination .page-item .page-link:hover {
    background: #42a8ff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px; 
}
.dataTables_paginate .pagination .page-item .page-link {
    font-size: .75rem;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}
.pagination .page-item .page-link {
    background: transparent;
    color: #2e323c;
    margin: 0;
    border: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    min-width: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination .page-item .page-link:hover {
    background: #f5f6fa;
    color: #2e323c; 
}
.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}
.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
}
.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.dataTables_paginate .pagination .page-item.active .page-link {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}
pagination .page-item.active .page-link {
    background: #007ae1;
    color: #ffffff;
    pointer-events: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 122, 225, 0.15);
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
</style>