<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Payment
                </template>
            </page-header>
        </template>
			<div class="col-12">
                <div class="card">
                    <div class="card-body">
                    <!-- Wizard start -->
                        <div id="example-form" role="application" class="wizard clearfix"> 
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" :class="classOne" aria-disabled="false" aria-selected="true">
                                        <a id="example-form-t-0" href="#example-form-h-0" aria-controls="example-form-p-0">
                                            <span class="current-info audible">current step: </span>
                                            <span class="number">1</span> 
                                            Payment Method
                                        </a>
                                    </li>
                                    <li role="tab" :class="classTwo" aria-disabled="false" aria-selected="false">
                                        <a id="example-form-t-1" href="#example-form-h-1" aria-controls="example-form-p-1">
                                            <span class="number">2</span> 
                                            Amount Specification
                                            </a>
                                    </li>
                                    <li role="tab" :class="classThree" aria-disabled="false" aria-selected="false">
                                        <a id="example-form-t-2" href="#example-form-h-2" aria-controls="example-form-p-2">
                                            <span class="number">3</span> 
                                            Payment Confirmation
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="content clearfix">
                                <h3 id="example-form-h-0" tabindex="-1" class="title current">Payment Method</h3>
                                <section id="example-form-p-0" role="tabpanel" aria-labelledby="example-form-h-0" class="body" aria-hidden="false" style="left: 0px;" v-show="currentStep == 1">
                                    <div class="row gutters">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline1" value="wallet" v-model="picked" name="customRadioInline1" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline1">Wallet</label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadioInline2" value="mpesa" v-model="picked" name="customRadioInline2" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadioInline2">Mpesa</label>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <h3 id="example-form-h-1" tabindex="-1" class="title current">Amount</h3>
                                <section id="example-form-p-1" role="tabpanel" aria-labelledby="example-form-h-1" class="body current" aria-hidden="false" style="left: 0px;" v-show="currentStep == 2">
                                    <div class="row gutters">
                                       <!--  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" v-if="picked == 'mpesa'">
                                            <div class="form-group">
                                                <label for="phone">Phone</label>
                                                <input type="number" class="form-control" id="msisdn" placeholder="Phone Number" v-model="msisdn">
                                            </div>
                                        </div> -->
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            <div class="form-group">
                                                <label for="amount">Amount</label>
                                                <input type="number" class="form-control" id="amount" placeholder="Amount" v-model="amount">
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <h3 id="example-form-h-2" tabindex="-1" class="title">Payment Confirmation</h3>
                                <section id="example-form-p-2" role="tabpanel" aria-labelledby="example-form-h-2" class="body" aria-hidden="false" style="left: 0px;" v-show="currentStep == 3">
                                    <div class="row gutters">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="form-group" v-if="picked == 'mpesa'">
                                                <label for="cardNum" class="my-custom-font">Pay {{ amount}} from {{ msisdn }}</label>
                                            </div>
                                             <div class="form-group" v-if="picked == 'wallet'">
                                                <label for="cardNum" class="my-custom-font">Pay {{ amount}} from wallet</label>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div class="actions clearfix">
                                <ul role="menu" aria-label="Pagination">
                                    <li :class="prevBtnClass" aria-disabled="true">
                                        <a role="menuitem" @click="goToPrevious">
                                            Previous
                                        </a>
                                    </li>
                                    <li v-show="currentStep != 3" aria-hidden="false" aria-disabled="false" class="" style="">
                                        <a role="menuitem" @click="goToNext">
                                            Next
                                        </a>
                                    </li>
                                    <li aria-hidden="true" style="display: none;" v-show="currentStep == 3">
                                        <a role="menuitem" @click.once="makePayment">
                                        Make Payment
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                        <!-- Wizard end -->    
                    </div>
                </div>
            </div>
        
    </base-component>
</template>

<script>
import BaseComponent from '../ui/BaseComponent.vue';
import PageHeader from '../layout/ThePageHeader.vue';

export default {
    props: ['project_code', 'project_id'],
    components: {
        BaseComponent,
        PageHeader
    },
    data() {
        return {
            amount: '',
            phone: '',
            currentStep: 1,
            stepCount: 3,
            userId: this.$store.getters.userId,
            error: '',
            amountValidationError: '',
            classOne: 'current',
            classTwo: 'disabled',
            classThree: 'disabled',
            picked: 'wallet',
            prevBtnClass: 'disabled',
            walletData: '',
            msisdn: this.$store.getters.phoneNumber,

        }
    },
    mounted(){
        const actionPayload = {
                    userId: this.userId
                }
        let wallet = this.$store.dispatch('getWallet', actionPayload);
        wallet.then(res => this.walletData = res)
        .catch(err => console.log(err));
    },
    methods: {
        goToNext() {
            if (this.currentStep == 1) {
                this.currentStep++;
                this.classOne = 'done';
                this.classTwo = 'current';
                this.prevBtnClass = 'active';
            } else if (this.currentStep == 2) {
                this.currentStep++;
                this.classTwo = 'done';
                this.classThree = 'current';
                this.prevBtnClass = 'active';
            } else {
                return;
            }
        },
        goToPrevious() {
            if (this.currentStep == 2) {
                this.currentStep--;
                this.classTwo = 'disabled';
                this.classOne = 'current';
                this.prevBtnClass = 'disabled';
            } else if (this.currentStep == 3) {
                this.currentStep--;
                this.classThree = 'disabled';
                this.classTwo = 'current';
                this.prevBtnClass = 'active';
            } else {
                return;
            }
        },
        async makePayment() {
            if (this.picked == 'mpesa') {
                const actionPayload = {
                amount: this.amount,
                msisdn: this.msisdn,
                destination: this.project_code
            };
                try {
                    await this.$store.dispatch('sendStk', actionPayload);
                    this.$router.push({name: 'project_details', params: { project_code: this.project_code }});
                } catch (err) {
                    if (Object.prototype.hasOwnProperty.call(err, 'amount')) {
                        this.amountValidationError = err.amount;
                    } else {
                        console.log(err);
                        this.error = err;
                    }
                }
            }
            if (this.picked == 'wallet') {
                const actionPayload = {
                    amount: this.amount,
                    projectCode: this.project_code,
                    walletId: this.walletData.walletId,
                    userId: this.userId
                }
                try {
                    await this.$store.dispatch('makeWalletPayment', actionPayload);
                    if (this.project_id && this.project_id == 1) {
                        this.$router.push({name: 'savings', params: { id: this.userId }});
                    } else {
                        this.$router.push({name: 'project_details', params: { project_code: this.project_code }});
                    }
                } catch (err) {
                    if (Object.prototype.hasOwnProperty.call(err, 'amount')) {
                        this.amountValidationError = err.amount;
                    } else {
                        console.log(err);
                        this.error = err;
                    }
                }
            }
        }
    },
    computed: {
        firstStep() {
            return this.currentStep == 1;
        },
        lastStep() {
            return this.currentStep == this.stepCount;
        },
    },
    
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem; 
}
.card .card-header {
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.25rem 1rem 1.25rem; 
}
.card .card-header .card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%; 
}
.card .card-header .card-sub-title {
    margin-top: 1rem;
    font-size: .8rem;
    font-weight: 400;
    color: #9fa8b9;
    line-height: 150%; 
}
.card.primary {
    background: #007ae1;
    color: #ffffff; 
}
.card.secondary {
    background: #ff3e61;
    color: #ffffff; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.card .card-footer {
    background: transparent;
    border-top: 0;
    padding: 1rem 1.25rem; 
}
.card .card-footer .view-all {
    color: rgba(0, 0, 0, 0.4);
    font-size: .8rem; 
}
.card .card-footer .view-all i {
    font-size: 1.2rem;
    vertical-align: middle; 
}
.card .card-footer .view-all:hover {
    color: #007ae1; 
}
.card .card-footer a.view {
    color: rgba(0, 0, 0, 0.4);
    font-size: .825rem;
    color: #007ae1;
    font-weight: 700; 
}
.card .card-footer a.view i {
    font-size: 1.5rem;
    margin: 0 10px;
    vertical-align: middle; 
}
.card .card-link {
    color: #007ae1;
    font-weight: 600; 
}
.card .card-link.primary {
    color: #007ae1; 
}
.card .card-link.secondary {
    color: #ff3e61; 
}
.card .card-img-bottom {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0; 
}
.card .card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 
}
.card.highlight {
    border: 1px solid #00d4fd;
    background: linear-gradient(120deg, #00d4fd 0%, #0065fd 100%); 
}
.card-deck {
  margin-right: -10px;
  margin-left: -10px; 
}
.card-deck .card {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 1.25rem; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.form-group {
    margin: 0 0 1rem 0; 
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c; 
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::-webkit-input-placeholder {
    color: #9fa8b9; 
}
.form-control:-moz-placeholder {
    color: #9fa8b9; 
}
.form-control::-moz-placeholder {
    color: #9fa8b9; 
}
.form-control:-ms-input-placeholder {
    color: #9fa8b9; 
}
.form-control:hover {
    border: 1px solid #007ae1; 
}
.form-control:focus {
    border-color: #007ae1;
    box-shadow: none;
    background: #ffffff;
    color: #2e323c; 
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f5f6fa; 
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.gutters > .col, .gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #ff3e3e;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: block;
}
.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
.wizard, .tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
    border: 1px solid #cfd1d8;
    margin-bottom: 1.25rem;
}
.wizard {
    margin: 0;
}
.wizard > .steps {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 1.25rem;
    border-bottom: 1px solid #cfd1d8;
}
.wizard ul, .tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
}
.wizard > .steps > ul > li, .wizard > .actions > ul > li {
    float: left;
}
.wizard > .steps > ul > li {
    width: auto;
}
.wizard ul > li, .tabcontrol ul > li {
    display: block;
    padding: 0;
}
.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
    color: #017ae1;
}
.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
    display: block;
    width: auto;
    margin: 0 15px 0 0;
    padding: 10px 0;
}
.wizard a, .tabcontrol a {
    outline: 0;
}
.wizard > .steps .current-info, .tabcontrol > .steps .current-info {
    position: absolute;
    left: -999em;
}
.wizard > .steps > ul > li.current a .number {
    background: #017ae1;
    border: 0;
    line-height: 36px;
    color: #ffffff;
}
.wizard > .steps > ul > li a .number {
    width: 36px;
    height: 36px;
    background: #f5f6fa;
    color: #9fa8bb;
    border: 2px solid #9fa8bb;
    display: inline-block;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    border-radius: 50px;
    margin-right: 5px;
}
.wizard > .steps .number {
    font-size: 1rem;
}
.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
    color: #00bb42;
}
.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
    color: #9fa8bb;
}
.wizard > .steps > ul > li.done a .number {
    background: #00bb42;
    border: 0;
    line-height: 36px;
    color: #ffffff;
}
.wizard > .content {
    background: #ffffff;
    display: block;
    margin: 0;
    min-height: 100px;
    overflow: hidden;
    position: relative;
    width: auto;
    border-bottom: 1px solid #cfd1d8;
}
.wizard > .content>.title, .tabcontrol > .content>.title {
    position: absolute;
    left: -999em;
}
.wizard > .content > .body {
    float: left;
    position: absolute;
    padding: 1.25rem;
    width: 100%;
    height: 100%;
}
.wizard > .actions {
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
    border: 0;
    border-top: 0;
    padding: 10px 1.25rem;
}
.wizard > .actions > ul {
    display: inline-block;
    text-align: right;
}
.wizard > .actions > ul > li {
    margin: 0 0 0 7px;
}
.wizard > .steps > ul > li, .wizard > .actions > ul > li {
    float: left;
}
.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
    background: #9fa8bb;
}
.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
    background: #017ae1;
    color: #fff;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: .85rem;
    font-weight: 600;
    line-height: 1.5;
}
.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.custom-radio .custom-control-label::before {
    border-radius: 50%;
}
.custom-control-label::before {
    top: 0;
    border-color: #007ae1;
    background: #f5f6fa;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
.custom-control-label::after {
    top: 0;
}
.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.custom-control-input:checked ~ .custom-control-label::before {
    background: #007ae1;
    border-color: #0074d6;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
    border-color: #0074d6;
    background-image: url(data:image/svg+xml,%3csvg)
}
.my-custom-font {
    font-size: 1.2rem;
}
</style>