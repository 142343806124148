<template>
    <div id="particles-js"></div>
		<div class="countdown-bg"></div>

		<div class="error-screen">
			<h1>404</h1>
			<h5>We're sorry! The page you have<br/>requested cannot be found.</h5>
            <router-link to="/login">
			<a class="btn">Go back to Dashboard</a>
            </router-link>
		</div>
</template>

<script>
export default {
    mounted() {
        document.body.classList.add('error-screen');
    },
    unmounted() {
       document.body.classList.remove('error-screen')
    }
}
</script>

<style scoped>
#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.countdown-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
}
.error-screen {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    padding: 5rem 0;
}
</style>