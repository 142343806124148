import axios from 'axios';
export default {
    async getLoansToBeReviewed(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan/awaiting_review`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async devloanReviewResponse(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan/review`, {
            loan_code: payload.loanCode,
            user_id: payload.userId,
            response: payload.response,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getUniflex(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/uniflex_applications`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            loan_status_filter: payload.loanStatus,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getDevLoans(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan_applications`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            loan_status_filter: payload.loanStatus,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getMembers(_, payload) {
        const response = await axios.post(process.env.VUE_APP_USERS_ENDPOINT + `api/get/members`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
            verification_status_filter: payload.verificationStatus,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getStkPayments(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PAYMENTS_ENDPOINT + `api/stk/payments`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getC2BPayments(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PAYMENTS_ENDPOINT + `api/c2b/payments`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getAllLoanSavings(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/savings`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        console.log(responseData);
        return responseData;
    },
    async getShamba(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/shamba`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getTargetSavings(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/all/targeted/saving`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getAllShares(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/all/shares`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getInsurance(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/insurance`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getUploadedPayments(_, payload) {
        const response = await axios.post(process.env.VUE_APP_WALLET_ENDPOINT + `api/uploaded/payments`, {
            page_size: payload.pageSize,
            page_index: payload.page,
            search_filter: payload.searchFilter,
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
}