<template>
    <base-component>
        <template #page-header>
            <page-header>
                <template #page-header-title>
                    Account Settings
                </template>
            </page-header>
        </template>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="account-settings">
                            <div class="user-profile">
                                <!-- <div class="user-avatar">
                                    <img src="@/assets/img/user2.png" alt="Tycoon Admin" />
                                </div> -->
                                <h5 class="user-name">Yuki Hayashi</h5>
                                <h6 class="user-email">yuki@tycoon.com</h6>
                            </div>
                            <div class="about">
                                <h5>About</h5>
                                <p>I'm Yuki. Full Stack Designer I enjoy creating user-centric, delightful and human experiences.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h6 class="mb-2 text-primary">Personal Details</h6>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="fullName">Full Name</label>
                                    <input type="text" class="form-control" id="fullName" placeholder="Enter full name">
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="eMail">Email</label>
                                    <input type="email" class="form-control" id="eMail" placeholder="Enter email ID">
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input type="text" class="form-control" id="phone" placeholder="Enter phone number">
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="text-right">
                                    <button type="button" id="submit" name="submit" class="btn btn-secondary">Cancel</button>
                                    <button type="button" id="submit" name="submit" class="btn btn-primary">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </base-component>
</template>

<script>
import BaseComponent from '../ui/BaseComponent.vue';
import PageHeader from '../layout/ThePageHeader.vue';

export default {
    components: {
        BaseComponent,
        PageHeader
    },
}
</script>

<style scoped>
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem; 
}
.card .card-header {
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1.25rem 1rem 1.25rem; 
}
.card .card-header .card-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%; 
}
.card .card-header .card-sub-title {
    margin-top: 1rem;
    font-size: .8rem;
    font-weight: 400;
    color: #9fa8b9;
    line-height: 150%; 
}
.card.primary {
    background: #007ae1;
    color: #ffffff; 
}
.card.secondary {
    background: #ff3e61;
    color: #ffffff; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.card .card-footer {
    background: transparent;
    border-top: 0;
    padding: 1rem 1.25rem; 
}
.card .card-footer .view-all {
    color: rgba(0, 0, 0, 0.4);
    font-size: .8rem; 
}
.card .card-footer .view-all i {
    font-size: 1.2rem;
    vertical-align: middle; 
}
.card .card-footer .view-all:hover {
    color: #007ae1; 
}
.card .card-footer a.view {
    color: rgba(0, 0, 0, 0.4);
    font-size: .825rem;
    color: #007ae1;
    font-weight: 700; 
}
.card .card-footer a.view i {
    font-size: 1.5rem;
    margin: 0 10px;
    vertical-align: middle; 
}
.card .card-link {
    color: #007ae1;
    font-weight: 600; 
}
.card .card-link.primary {
    color: #007ae1; 
}
.card .card-link.secondary {
    color: #ff3e61; 
}
.card .card-img-bottom {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0; 
}
.card .card-img-top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; 
}
.card.highlight {
    border: 1px solid #00d4fd;
    background: linear-gradient(120deg, #00d4fd 0%, #0065fd 100%); 
}
.card-deck {
  margin-right: -10px;
  margin-left: -10px; 
}
.card-deck .card {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 1.25rem; 
}
.card .card-body {
    padding: 1.25rem 1.25rem;
    position: relative; 
}
.card .card-body .card-title {
    margin-bottom: .7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 100%; 
}
.card .card-body .card-sub-title {
    margin-bottom: 1rem;
    font-size: .8rem;
    color: #9fa8b9;
    line-height: 140%; 
}
.card .card-body .card-text {
    margin-bottom: 1rem;
    line-height: 180%; 
}
.form-group {
    margin: 0 0 1rem 0; 
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c; 
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control::-webkit-input-placeholder {
    color: #9fa8b9; 
}
.form-control:-moz-placeholder {
    color: #9fa8b9; 
}
.form-control::-moz-placeholder {
    color: #9fa8b9; 
}
.form-control:-ms-input-placeholder {
    color: #9fa8b9; 
}
.form-control:hover {
    border: 1px solid #007ae1; 
}
.form-control:focus {
    border-color: #007ae1;
    box-shadow: none;
    background: #ffffff;
    color: #2e323c; 
}
.form-control:disabled, .form-control[readonly] {
    background-color: #f5f6fa; 
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.gutters {
    margin-right: -8px;
    margin-left: -8px; 
}
.gutters > .col,
.gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; 
}
.gutters > .col, .gutters > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}
label {
    margin-bottom: .3rem;
    font-size: .725rem;
    font-weight: 600;
    display: inline-block;
}
.h-100 {
    height: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}
.min-vh-100 {
    min-height: 100vh !important;
}
.vh-100 {
    height: 100vh !important;
}
.account-settings .user-profile {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  text-align: center; 
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0; 
}
.account-settings .user-profile .user-avatar img {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px; 
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 .5rem 0; 
}
.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: .8rem;
    font-weight: 400;
    color: #9fa8b9; 
}
.account-settings .about {
  margin: 2rem 0 0 0;
  text-align: center; 
}
.account-settings .about h5 {
    margin: 0 0 15px 0;
    color: #007ae1; 
}
.account-settings .about p {
    font-size: .825rem; 
}
.mb-2, .my-2 {
    margin-bottom: 0.5rem !important;
}
.text-right {
    text-align: right!important;
}
</style>