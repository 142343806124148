<template>
    <!-- Page content start  -->
    <div class="page-content">
       <slot></slot>
    </div>
</template>

<script>


export default {
    components: {
    }
}
</script>
<style scoped>
.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    -webkit-transition: padding-left 0.5s ease;
    -moz-transition: padding-left 0.5s ease;
    -ms-transition: padding-left 0.5s ease;
    -o-transition: padding-left 0.5s ease;
    transition: padding-left 0.5s ease;
    padding: 0 0 0 250px; 
}
.page-wrapper.pinned .page-content {
    padding-left: 65px; 
}
@media screen and (max-width: 768px){
.page-wrapper .page-content {
    padding-left: 0;
}
}
</style>