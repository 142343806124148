<template>
    <!-- Sidebar wrapper start -->
	<nav id="sidebar" class="sidebar-wrapper">
        <!-- Sidebar content start -->
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
            <div class="sidebar-content">
                <!-- sidebar menu start -->
                <div class="sidebar-menu">
                    <ul v-if="userType == '1'">
                        <li>
                            <router-link to="/dashboard">
                                <i class="icon-home2"></i>
                                <span class="menu-text">Dashboard</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'member-wallet', params: { id: userId}}">
                                <i class="icon-layers2"></i>
                                <span class="menu-text">
                                    Wallet
                                </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'savings', params: { id: userId}}">
                                <i class="icon-credit"></i>
                                <span class="menu-text">Loan Savings</span>
                            </router-link>
                        </li>
                        <li class="sidebar-dropdown" @click="toggle">
                            <a>
                                <i class="icon-shield"></i>
                                <span class="menu-text">Loans</span>
                            </a>
                            <div class="sidebar-submenu" style="display: block;" v-if="isActive">
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'devloans', params: { id: userId}}">
                                        Dev Loans
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'uniflexloans', params: { id: userId}}">
                                        Uniflex Loans
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li class="sidebar-dropdown" @click="toggleOtherProjects">
                            <a>
                                <i class="icon-pin"></i>
                                <span class="menu-text">Other Products</span>
                            </a>
                            <div class="sidebar-submenu" style="display: block;" v-if="isOtherProjectsActive">
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'member_shamba', params: { id: userId}}">
                                        Shamba project
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'member_insurance', params: { id: userId}}">
                                        Insurance
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'member_targeted_savings', params: { id: userId}}">
                                        Targeted Savings
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'member-shares', params: { id: userId}}">
                                        Shares
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>

                    <ul v-if="userType == '3' || userType == '2' || userType == '5' || userType == '4' || userType == '6' || userType == '7' || userType == '8' ">
                         <li>
                            <router-link to="/dashboard">
                                <i class="icon-home2"></i>
                                <span class="menu-text">Dashboard</span>
                            </router-link>
                        </li>
                        <li v-if="userType == '3'">
                            <router-link :to="{ name: 'devloansForApproval'}">
                                <i class="icon-credit"></i>
                                <span class="menu-text">Devloans For Review</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustDevLoans'}">
                                <i class="icon-credit"></i>
                                <span class="menu-text">Devloans</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustUniflexLoans'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Uniflex Loans</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustMembers'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Members</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustLoanSavings'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Loan Savings</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustC2BPayments'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">C2B Payments</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustShamba'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Shamba</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustTargetSavings'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Target Savings</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustShares'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Shares</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustInsurance'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Insurance</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'unitrustUploadedPayments'}">
                                <i class="icon-beenhere"></i>
                                <span class="menu-text">Uploaded Payments</span>
                            </router-link>
                        </li>
                     </ul>

                </div>
                <!-- sidebar menu end -->
            </div>
            <div class="slimScrollBar" style="background: rgb(225, 229, 241); width: 5px; position: absolute; top: -23px; opacity: 0.8; display: none; border-radius: 0px; z-index: 99; left: 1px; height: 30px;">
            </div>
            <div class="slimScrollRail" style="width: 5px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 0px; background: rgb(225, 229, 241); opacity: 0.2; z-index: 90; left: 1px;">
            </div>
           
        </div>
		<!-- Sidebar content end -->
	</nav>
	<!-- Sidebar wrapper end -->
</template>

<script>
export default {
    data() {
        return {
            active: false,
            otherProjectsActive: false,
            classActive: false,
            listActive: false,
            userId: this.$store.getters.userId,
            userType: this.$store.getters.userType,
        }
    },
    methods: {
        toggle() {
            this.active = !this.active
        },
        toggleList() {
            this.listActive = !this.listActive
        },
        toggleOtherProjects() {
            this.otherProjectsActive = !this.otherProjectsActive
        },
    },
    computed: {
        isActive() {
            return this.active
        },
        isOtherProjectsActive() {
            return this.otherProjectsActive
        },
        classObject() {
            return {
                active: !this.classActive
            }
        },
        listObject() {
            return {
                active: this.listActive
            }
        }
    }
}
</script>

<style scoped>
.page-wrapper .sidebar-wrapper {
    width: 250px;
    height: 100vh;
    background: #ffffff;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    -webkit-box-shadow: 20px 0 20px -20px #d3d7e9;
    -moz-box-shadow: 20px 0 20px -20px #d3d7e9;
    box-shadow: 20px 0 20px -20px #d3d7e9;
    -webkit-transition: left .3s ease, width .3s ease;
    transition: left .3s ease, width .3s ease;
}
@media screen and (max-width: 768px) {
    .page-wrapper .sidebar-wrapper {
    left: -265px; }
    .page-wrapper.toggled .sidebar-wrapper {
    left: 0; }
    .page-wrapper.toggled .header {
    padding-left: 245px !important; }
    .page-wrapper .page-content {
    padding-left: 0; }
    .page-wrapper.pinned .page-content {
    padding-left: 0; } }
@media (min-width: 768px) {
    .page-wrapper.pinned:not(.sidebar-hovered) .sidebar-wrapper {
    width: 65px; }
    .page-wrapper.pinned:not(.sidebar-hovered) .sidebar-wrapper .sidebar-menu .sidebar-submenu,
    .page-wrapper.pinned:not(.sidebar-hovered) .sidebar-wrapper .sidebar-menu ul > li > a > span,
    .page-wrapper.pinned:not(.sidebar-hovered) .sidebar-wrapper .sidebar-menu ul > li > a::after {
    display: none !important; }
}
.page-wrapper.sidebar-hovered .sidebar-wrapper .sidebar-brand .logo {
    max-width: 100%; }
.page-wrapper.sidebar-hovered .sidebar-wrapper .sidebar-user-details .user-profile img.profile-thumb {
    width: 72px;
    height: 72px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
.page-wrapper.sidebar-hovered .sidebar-wrapper .sidebar-user-details .user-profile h6.profile-name {
    max-width: 150px; }
.page-wrapper.sidebar-hovered .sidebar-wrapper .sidebar-user-details .user-profile .profile-status .progress {
    min-width: 150px; }
.page-wrapper .sidebar-wrapper .sidebar-content {
    position: relative;
    height: calc(100% - 300px) !important;
}
.page-wrapper.pinned .sidebar-wrapper .sidebar-brand .logo {
    max-width: 65px;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis; 
}
.page-wrapper.pinned .sidebar-wrapper .sidebar-user-details .user-profile img.profile-thumb {
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; 
}
.page-wrapper.pinned .sidebar-wrapper .sidebar-user-details .user-profile h6.profile-name {
    max-width: 40px;
    margin: 10px auto 0 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}
.page-wrapper.pinned .sidebar-wrapper .sidebar-user-details .user-profile .profile-status .progress {
    min-width: 40px; 
}
.page-wrapper.pinned .sidebar-wrapper .sidebar-user-details .user-profile .profile-actions a {
    font-size: .65rem;
    text-align: center;
    display: block;
    width: 50px;
    color: #2e323c;
    position: relative;
    padding: 3px 0; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    padding: 5px 35px 5px 15px;
    width: 100%;
    color: #4d4d4d;
    font-size: .8rem;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 7px;
    font-size: 1.2rem;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; 
}
 .page-wrapper .sidebar-wrapper .sidebar-menu ul li a .menu-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    overflow: hidden; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a:hover {
    background: #f5f6fa;
    color: #007ae1; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a:hover i {
    color: #007ae1; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active-page-link {
    position: relative; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active-page-link a {
        background: #007ae1;
        color: #ffffff; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active-page-link a i {
    color: #007ae1; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active-page-link:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: 0px;
    margin: -10px 0 0 0;
    border: 10px solid;
    border-color: transparent #f5f6fa transparent transparent; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active a.current-page {
        background: transparent !important;
        color: #007ae1; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li.active .sidebar-submenu {
    display: block; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
    font-family: "icomoon";
    font-weight: 100;
    font-size: 1rem;
    content: "\e911";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 13px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu {
    display: none; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 0; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 11px 35px 11px 35px;
    font-size: .8rem; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
    font-family: "icomoon";
    font-weight: 700;
    content: "\e9d3";
    margin: 0 10px 0 0;
    font-size: .7rem; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:hover {
    background: #f5f6fa;
    color: #007ae1; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 15px;
    color: #ffffff; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .active > a {
    background: #007ae1 !important;
    color: #ffffff !important;
    position: relative; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .active > a:before {
    content: '';
    position: absolute;
    right: -1px;
    top: 1px;
    background: url("~@/assets/img/menu-arrow-white.svg");
    background-size: 100%;
    width: 9px;
    height: 40px; 
}
.page-wrapper .sidebar-wrapper .sidebar-menu .active > a i {
    color: #ffffff !important; 
}

</style>