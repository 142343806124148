<template>
    <!-- Page wrapper start -->
      <div class="page-wrapper " :class="setSideBar">
           <span  v-on:mouseover="showSidebar" @mouseleave="hideSidebar"></span>
           <slot></slot>
      </div>
</template>

<script>
export default {
     data() {
          return {
               pinned: this.$store.getters.toggleSidebar, 
          };
   }, 
   computed: {
          setSideBar() {
               return this.$store.getters.toggleSidebar;
          }
   },
   methods: {
     showSidebar() {
               const actionPayload = {
                    sideBarValue: 'toggled sidebar-hovered'
           };
               this.$store.dispatch('toggleSidebar',actionPayload);
        },
     hideSidebar() {
          const actionPayload = {
               sideBarValue: ''
          };
          this.$store.dispatch('toggleSidebar',actionPayload);
     }
   }
}
</script>
<style>
.page-wrapper {
	background: #f5f6fa; 
}
.page-wrapper.pinned .page-content {
	padding-left: 65px; 
}
</style>