<template>
    <!-- Page header start -->
	<div class="page-header">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <slot name="page-header-title">Welcome, Member</slot>
            </li>
        </ol>
	</div>
</template>

<style scoped>
.page-header {
    padding: 0;
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; 
}
.breadcrumb {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background: transparent;
    margin: 0;
    padding: .3rem 0;
    font-size: 1.2rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none; 
}
.breadcrumb-item {
    display: flex;
}
.breadcrumb .breadcrumb-item {
    color: #2e323c; 
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #2e323c; 
}
.breadcrumb .breadcrumb-item a {
    color: #2e323c; 
}
.breadcrumb .breadcrumb-item.active {
    color: #2e323c; 
}
</style>