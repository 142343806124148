import axios from 'axios';
export default {
    async getWallet(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_WALLET_ENDPOINT + `api/wallet_account/${userId}`);
        const responseData = response.data.data;
        const creditData = responseData.wallet_credit;
        const debitData = responseData.wallet_debit;
        const balance = responseData.wallet_balance.credits - responseData.wallet_balance.debits;

        var val = creditData.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.transaction_amount;
        }, 0);
        var val2 = debitData.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.amount;
        }, 0);

        creditData.forEach(function(element) {
            element.transaction_type = "credit";
        });
        debitData.forEach(function(element) {
            element.transaction_type = "debit";
        });
        var newData = creditData.concat(debitData);
        newData.sort(function(a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });

        return {
            credits: val,
            debits: val2,
            balance: balance,
            walletTransactions: newData,
            walletId: responseData.id
        };
    },
    async walletMeta(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_WALLET_ENDPOINT + `api/wallet_account/${userId}`);
        const responseData = response.data.data;
        const creditData = responseData.wallet_credit;
        const debitData = responseData.wallet_debit;
        const balance = responseData.wallet_balance.credits - responseData.wallet_balance.debits;

        var val = creditData.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.transaction_amount;
        }, 0);
        var val2 = debitData.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.amount;
        }, 0);

        return {
            credits: parseInt(val),
            debits: parseInt(val2),
            balance: parseInt(balance),
        };
    },
    async savingsTotal(_, payload) {
        const userId = payload.userId;
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/savings/${userId}/total`);
        return response;
    },
    async memberSavings(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/savings/${userId}`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        return response;
    },
    async getCreditsTotal(_, walletArray) {
        var val = walletArray.wallet_credit.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.transaction_amount;
        }, 0);
        var val2 = walletArray.wallet_debit.reduce(function(previousValue, currentValue) {
            return previousValue + currentValue.amount;
        }, 0);
        return {
            credits: val,
            debits: val2,
        };
    },
    async memberUniflexLoans(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/uniflex_applications/${userId}`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        return response;
    },
    async memberDevLoans(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan_applications/${userId}`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        return response;
    },
    async memberDevLoanTotal(_, payload) {
        const userId = payload.userId;
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/devloan/${userId}/total`);
        return response;
    },
    async memberDevLoanBalance(_, payload) {
        const userId = payload.userId;
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/devloan/${userId}/balance`);
        return response;
    },
    async memberUniflexTotal(_, payload) {
        const userId = payload.userId;
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/uniflex/${userId}/total`);
        return response;
    },
    async memberUniflexBalance(_, payload) {
        const userId = payload.userId;
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/uniflex/${userId}/balance`);
        return response;
    },
    async sendUniflexApplication(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/apply/uniflex/${userId}`, {
            amount: payload.amount
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async sendDevLoanApplication(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/apply/devloan/${userId}`, {
            amount: payload.amount,
            duration: payload.duration,
            guarantor_phone_number: payload.guarantorPhone,
            guarantor_amount: payload.guarantorAmount
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async memberShambaProject(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/shamba/${userId}`);
        return response;
    },
    async memberInsuranceProjects(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/insurance/${userId}`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async memberTargetedSavings(_, payload) {
        const userId = payload.userId;
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/targeted_saving/${userId}`, {
            page_size: payload.pageSize,
            page_index: payload.page
        });
        const responseData = response.data;
        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async subscribeToProject(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/project_subscription`, {
            user_id: payload.userId,
            company_project_id: payload.projectId,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async getSpecificProject(context, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/projects/${payload.userId}`, {
            project_id: payload.projectId,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        context.commit('setProjectCode', {
            projectCode: responseData.data[0].project_code
        });
        return responseData;
    },
    async getProjectUsingCode(context, payload) {
        const response = await axios.get(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/member/project/${payload.projectCode}?payments_page_size=${payload.paymentsPageSize}&payments_page_index=${payload.paymentsPage}&penalties_page_size=${payload.penaltiesPageSize}&penalties_page_index=${payload.penaltiesPage}`, {

            penalties_page_size: payload.penaltiesPageSize,
            penalties_index: payload.penaltiesPage
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async sendStk(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PAYMENTS_ENDPOINT + `api/stkPush`, {
            msisdn: payload.msisdn,
            amount: payload.amount,
            destination: payload.destination
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },

    async makeWalletPayment(_, payload) {
        const response = await axios.post(process.env.VUE_APP_WALLET_ENDPOINT + `api/debit_wallet`, {
            wallet_id: payload.walletId,
            amount: payload.amount,
            user_id: payload.userId,
            payment_destination: payload.projectCode
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async createInsurance(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/project_subscription`, {
            user_id: payload.userId,
            company_project_id: payload.projectId,
            insurance_name: payload.insuranceName,
            monthly_premium: payload.monthlyPremium
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async createTargetedSavings(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/project_subscription`, {
            user_id: payload.userId,
            company_project_id: payload.projectId,
            goal_name: payload.goalName,
            target_amount: payload.targetAmount,
            start_date: payload.startDate,
            end_date: payload.endDate
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
    },
    async getUniflexMax(context, payload) {
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/uniflex/${payload.userId}/available_funds`);
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getGuarantorSummary(context, payload) {
        const response = await axios.get(process.env.VUE_APP_LOANS_ENDPOINT + `api/member/devloan/${payload.userId}/guarantor_summary`);
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getLoansGuaranteed(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan_guaranteed/${payload.userId}`);
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async getLoansToBeGuaranteed(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan/${payload.userId}/requests`);
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async guarantorResponse(_, payload) {
        const response = await axios.post(process.env.VUE_APP_LOANS_ENDPOINT + `api/devloan/${payload.userId}/response`, {
            loan_code: payload.loanCode,
            response: payload.response,
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },

    async getShares(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/get/shares`, {
            user_id: payload.userId,
            page_size: payload.pageSize,
            page_index: payload.page
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },
    async buyShares(_, payload) {
        const response = await axios.post(process.env.VUE_APP_PROJECTS_ENDPOINT + `api/shares/purchase`, {
            user_id: payload.userId,
            no_of_shares: payload.no_of_shares,
            share_price: payload.shareValue,
            amount_paid: payload.amount,
            phone_no: payload.msisdn,
            payment_mode: payload.picked
        });
        const responseData = response.data;

        if (responseData.status_code == 422) {
            const error = responseData.error;
            throw error;
        }
        return responseData;
    },


}